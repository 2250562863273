import React from 'react'

const HeaderNeutro = () => (
  <>
    <div className="header pt-lg-7 d-flex align-items-center">
      <span className="mask opacity-8" />
    </div>
  </>
)

export default HeaderNeutro
