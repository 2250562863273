import React, { useMemo, useState, useCallback } from 'react'

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useDropzone } from 'react-dropzone'
import ReactDatetime from 'react-datetime'
import { subDays, format } from 'date-fns'

import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Media,
} from 'reactstrap'

import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'

const schemaRegister = yup.object().shape({
  title: yup.string().required('Informe o título!'),
  date: yup.string().required('Informe a data!'),
})

const ModalCreateEvent: React.FC<any> = ({
  modal,
  setModal,
  setLoading,
  action,
}) => {
  const [banner, setBanner] = useState<any>()

  const initialValues = useMemo(
    () => ({
      title: '',
      date: '',
      link: '',
    }),
    []
  )

  const onDrop = useCallback((acceptedFiles) => {
    setBanner(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
  })

  const isValidDate = (currentDate: any) =>
    currentDate.isAfter(subDays(new Date(), 1))

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const data = new FormData()
      if (banner) {
        banner.map((file: any) => data.append('file', file))
      }
      data.append('title', values.title)
      data.append('date', format(new Date(values.date), 'yyyy-MM-dd'))
      data.append('link', values.link)
      data.append('application', 'company') // Campo application
      try {
        await api.post('/backoffice/ms/v1/events', data)
        alertSuccess('Evento criado com sucesso!')
        formik.resetForm()
        setBanner(null)
        setModal(false)
        action()
      } catch (err: any) {
        alertWarning(
          err?.response?.data?.message || 'Não foi possível criar esse evento'
        )
      }
      setLoading(false)
    },
  })

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal)
        }}
      >
        <ModalHeader>
          <div>
            <h3>Criar Evento</h3>
          </div>
        </ModalHeader>
        <ModalBody className="p-4">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Titulo
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Informe o titulo.."
                      name="title"
                      type="text"
                      maxLength={18}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title || ''}
                      error={formik.errors.title}
                    />
                    {formik.errors.title && formik.touched.title && (
                      <div className="input-feedback">
                        {formik.errors.title}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup style={{ width: '100%' }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Data do evento
                    </label>
                    <ReactDatetime
                      className="form-control-alternative"
                      inputProps={{
                        placeholder: 'Dia',
                        style: {
                          width: 200,
                        },
                      }}
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          date: e.toString(),
                        })
                      }}
                      isValidDate={isValidDate}
                      value={formik.values.date || ''}
                    />
                    {formik.errors.date && formik.touched.date && (
                      <div className="input-feedback">{formik.errors.date}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Link do evento
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="https://www.exemplo.com.br"
                      name="link"
                      type="text"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.link || ''}
                      error={formik.errors.link}
                    />
                    {formik.errors.link && formik.touched.link && (
                      <div className="input-feedback">{formik.errors.link}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12" className="d-flex flex-column align-items-center ">
                  <Media className="d-flex flex-column">
                    <label className="form-control-label" htmlFor="logo">
                      Banner
                    </label>
                    <span className="text-wrap" style={{ fontSize: 14 }}>
                      Obs:
                    </span>
                    <span className="text-wrap" style={{ fontSize: 14 }}>
                      - Aceita apenas arquivos .png e .jpeg
                    </span>
                    <span
                      className="text-wrap"
                      style={{ fontSize: 14, marginBottom: 5 }}
                    >
                      - Recomendado resolução da imagem 776 x 263 pixels
                    </span>
                    <input {...getInputProps()} />
                    <Media
                      {...getRootProps()}
                      style={{
                        width: '450px',
                        height: '200px',
                        borderRadius: '8px',
                        border: '3px dashed #dee2e6',
                        cursor: 'pointer',
                      }}
                      className=" align-items-center justify-content-center position-relative"
                    >
                      {banner && banner?.length ? (
                        <img
                          src={URL.createObjectURL(banner[0])}
                          alt="Logo"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <h5 className=" text-gray">Adicionar</h5>
                      )}
                    </Media>
                  </Media>
                </Col>
              </Row>
              <Row className="mt-3 d-flex justify-content-between">
                <Col sm="6">
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      formik.resetForm()
                      setModal(false)
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6">
                  <Button
                    style={{ minWidth: 200 }}
                    color="secondary"
                    type="submit"
                  >
                    Criar
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
    </div>
  )
}

export { ModalCreateEvent }
