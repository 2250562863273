import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getDoctorData } from 'services/doctor'
import { alertWarning } from 'utils/toast'

import { Col, Container, Row } from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'

import CardDoctor from './components/CardDoctor'
import UseDoctor from './components/UseDoctor'

const Doctor = () => {
  const [loading, setLoading] = useState(false)
  const [userDoctor, setUserDoctor] = useState<any>({})
  const [metrics, setMetrics] = useState({
    slots: 0,
    finished: 0,
    canceled: 0,
  })
  const { state }: any = useLocation()

  const doctorUser = async () => {
    setLoading(true)
    const response = await getDoctorData(state.params?.id)
    if (response.status) {
      setMetrics({
        slots: response.item?.slots,
        finished: response.item?.schedulings_finished,
        canceled: response.item?.schedulings_canceled,
      })
      setUserDoctor(response.item?.doctor)
    } else {
      alertWarning('Erro ao encontrar os dados do médico.')
    }
    setLoading(false)
  }

  useEffect(() => {
    doctorUser()
  }, [])

  if (loading) return <Loading title="Aguarde..." />
  return (
    <>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="m-0 p-0" fluid>
        <Row className="mt-3 align-items-start d-flex justify-content-between">
          <Col lg="8" className="justify-content-start">
            {userDoctor?.id && (
              <UseDoctor doctorData={userDoctor} doctorUser={doctorUser} />
            )}
          </Col>
          <Col lg="4">
            {userDoctor?.id && (
              <CardDoctor
                doctorCard={userDoctor}
                schedules={metrics.slots}
                schedulingsCanceled={metrics.canceled}
                schedulingsFinished={metrics.finished}
              />
            )}
          </Col>
          <Col />
        </Row>
      </Container>
    </>
  )
}

export default Doctor
