import React, { useEffect } from 'react'
// @ts-ignore
import Chart from 'chart.js'
// import { Bar } from 'react-chartjs-2'

// Components
import { Card, Container, Row, Col } from 'reactstrap'

import { chartOptions, parseOptions } from 'variables/charts'

// import { chartNewUsers } from 'utils/charts'
// import Header from 'components/Headers/Header'
import useSpecialty from 'hooks/useSpecialty'
// import useFetch from 'hooks/useFetch'
import { useAuth } from 'context/authentication/auth.context'

import ChartAppointments from './Components/ChartAppointments'
import ChartCsat from './Components/ChartCsat'
import ChartNps from './Components/ChartNps'

import * as S from './styles'

const Index = () => {
  const { getSpecialties } = useSpecialty()
  const { verifyUser } = useAuth()

  // @ts-ignore
  if (window.Chart) {
    parseOptions(Chart, chartOptions())
  }

  // const { data } = useFetch(`manager/reports/dash/users`)
  // const { data: newUsers } = useFetch(`manager/reports/dash/new-users`)

  useEffect(() => {
    getSpecialties()
    verifyUser()
  }, [])

  return (
    <div className=" d-flex flex-direction-column" style={{ width: '100%' }}>
      <S.BgContainer>
        {/* <Header stars={data?.stars} /> */}
        <Container className="mt-5" fluid>
          <Row>
            <Col xl="5">
              <Card className="shadow mt-3">
                <ChartAppointments />
              </Card>
              <Card className="shadow mt-3 mb-3">
                <ChartCsat />
              </Card>
            </Col>
            <Col xl="7">
              {/* <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Novas Estrelas</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {newUsers && (
                    <div className="chart">
                      <Bar
                        type="bar"
                        data={chartNewUsers(newUsers)?.data}
                        options={chartNewUsers(newUsers)?.options}
                      />
                    </div>
                  )}
                </CardBody>
              </Card> */}
              <Card className="shadow mt-3 mb-3">
                <ChartNps />
              </Card>
            </Col>
          </Row>
        </Container>
      </S.BgContainer>
    </div>
  )
}
export default Index
