import React from 'react'

// Components
import { Row, Col } from 'reactstrap'

const Footer = () => (
  <footer className="footer">
    <Row className="align-items-center justify-content-xl-between">
      <Col xl="6">
        <div className="copyright text-center text-xl-left text-muted">
          {`© 2020-${new Date().getFullYear()}`}
          <a
            className="font-weight-bold ml-1"
            href="https://starbem.app"
            rel="noopener noreferrer"
            target="_blank"
          >
            StarBem
          </a>
          <span> - Todos direitos reservados | Versão: 1.0.2</span>
        </div>
      </Col>
    </Row>
  </footer>
)

export default Footer
