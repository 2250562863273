import React from 'react'
import { useLocation, Route, Switch, Redirect } from 'react-router-dom'

import routes from 'routes'

const Auth = () => {
  const location = useLocation()

  React.useEffect(() => {
    document.body.classList.add('bg-default')
    return () => {
      document.body.classList.remove('bg-default')
    }
  }, [])
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    // @ts-ignore
    document.scrollingElement.scrollTop = 0
    // @ts-ignore
  }, [location])

  const getRoutes = (routesProp: any) =>
    routesProp.map((prop: any) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={prop.layout}
          />
        )
      }
      return null
    })

  return (
    <>
      <div className="login-bg">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
    </>
  )
}

export default Auth
