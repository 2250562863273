import React, { useEffect, useState } from 'react'
import api from 'configs/api'
import { telephoneMask } from 'js-essentials-functions'
// import { Link } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
} from 'reactstrap'
import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import moment from 'moment'

const generateStatus = (item: string) => {
  const statusInvoices: any = {
    paid: 'Pago',
    pending: 'Pendente',
    expired: 'Expirado',
    canceled: 'Cancelado',
  }

  return statusInvoices[item]
}

const History = () => {
  const [companies, setCompanies] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [type, setType] = useState('all')
  const [month, setMonth] = useState(new Date())

  // Paginação
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const getFinancialCompanies = async (
    numberPage = page,
    monthSearch = month
  ) => {
    setLoading(true)
    const formatMonth = moment(monthSearch).format('YYYY-MM')

    try {
      const response = await api.get(
        `manager/financial?page=${numberPage}&take=${limit}&status=${type}&month=${formatMonth}`
      )
      const datas = response.data.items.map((data: any) => ({
        code: data.code,
        id: data.id,
        name: data.company_name,
        email: data.company_email,
        cellphone: data.company_telephone,
        due_date: data.due_date,
        url: data.billet_url,
        status: data.status,
        created_at: data.created_at,
      }))
      if (Math.ceil(response.data.total / limit) === 1 || datas.length === 0) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setNumberOfPages(Math.ceil(response.data.total / limit))
      setCompanies(datas)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (numberOfPages === 1) {
      setPaginationOn(false)
    }
    getFinancialCompanies()
  }, [page, type])

  const changeDate = (value: any) => {
    setMonth(value)
    getFinancialCompanies(page, value)
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  return (
    <div>
      {loading ? (
        <Loading title="Carregando..." />
      ) : (
        <>
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center justify-content-between">
              <Col sm="auto" className="mb-2">
                <h3 className="mb-0 text-black">Boletos gerados</h3>
              </Col>
              <Col sm="9">
                <Row className="align-items-center justify-content-end">
                  <Col sm="auto">
                    <FormGroup className="mb-0">
                      <Input
                        type="select"
                        style={{ paddingRight: 10 }}
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <option value="all">Todos</option>
                        <option value="pending">Pendentes</option>
                        <option value="paid">Pagos</option>
                        <option value="expired">Expirados</option>
                        <option value="canceled">Cancelados</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="auto">
                    <div className="d-flex align-items-center">
                      <Button
                        className="btn-icon btn-2"
                        color="primary"
                        size="sm"
                        onClick={() => {
                          changeDate(moment(month).subtract(1, 'month'))
                        }}
                      >
                        <i className="fa fa-chevron-left" />
                      </Button>
                      <h4 className="mb-0 text-aling-center">
                        {moment(month).format('MMM YYYY')}
                      </h4>
                      <Button
                        className="btn-icon btn-2 ml-1"
                        color="primary"
                        size="sm"
                        disabled={verifyMonth}
                        onClick={() => {
                          changeDate(moment(month).add(1, 'month'))
                        }}
                      >
                        <i className="fa fa-chevron-right" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>

          <Row className="mt-0">
            <div className="col">
              <Card className="shadow">
                <Table
                  className="align-items-center table-flush"
                  responsive
                  hover
                  fluid="true"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">code</th>
                      <th scope="col">nome da empresa</th>
                      <th scope="col">email da empresa</th>
                      <th scope="col">telefone da empresa</th>
                      <th scope="col">data de vencimento</th>
                      <th scope="col">status</th>
                      <th scope="col">data de criação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    {companies?.map((company) => (
                      <tr key={company.id}>
                        <th>{company.code}</th>
                        <td>{company.name}</td>
                        <td>{company.email}</td>
                        <td>{telephoneMask(company.cellphone || '')}</td>
                        <td>
                          {moment(company.due_date || '').format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </td>
                        <td>{generateStatus(company?.status)}</td>
                        <td>
                          {moment(company.created_at || '').format(
                            'DD/MM/YYYY '
                          )}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            href={company.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Ver boleto
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {paginationOn && (
                  <PaginationComponent
                    numberOfPages={numberOfPages}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </Card>
            </div>
          </Row>
        </>
      )}
    </div>
  )
}

export default History
