export function formatCPF(value: any) {
  const numericValue = value.replace(/\D/g, '')
  if (numericValue.length < 11) {
    return numericValue
  }
  return `${numericValue.slice(0, 3)}.${numericValue.slice(
    3,
    6
  )}.${numericValue.slice(6, 9)}-${numericValue.slice(9)}`
}
