export const reports = (id: string) => [
  {
    id: 4,
    title: 'Quantidade e especialidades realizadas',
    description: 'Retorna a quantidade e especialidades realizadas por período',
    url: `/schedulings/ms/v1/companies/${id}/specialties/reports?`,
    filename: `Relatório-Especialidades-`,
  },
  {
    id: 5,
    title: 'Todos colaboradores',
    description: 'Retorna todos os colaboradores e dependentes da empresa',
    url: `/company/ms/v1/report/${id}/stars?type=&logged=&status=&`,
    filename: `Relatório-TodosColaboradores-`,
  },
  {
    id: 6,
    title: 'Todos titulares',
    description: 'Retorna todos os colaboradores titulares da empresa',
    url: `/company/ms/v1/report/${id}/stars?type=holder&logged=&status=&`,
    filename: `Relatório-Titulares-`,
  },
  {
    id: 7,
    title: 'Todos dependentes',
    description: 'Retorna todos os dependentes da empresa',
    url: `/company/ms/v1/report/${id}/stars?type=depedente&logged=&status=&`,
    filename: `Relatório-Dependentes-`,
  },
  {
    id: 8,
    title: 'Colaboradores removidos',
    description: 'Retorna os colaboradores removidos por período',
    url: `/company/ms/v1/report/${id}/stars?type=&logged=&status=deleted&`,
    filename: `Relatório-Colaboradores-Removidos-`,
  },
]

export const monthlyReport = (id: string, month: any, year: any) => [
  {
    id: 20,
    title: 'Lista de titulares e dependentes',
    description: 'Retorna todos os titulares e dependentes e seus valores',
    url: `/company/ms/v1/financial-report/${id}?month=${month}&year=${year}`,
    filename: `Relatório-titulares-e-dependentes-com-valores`,
  },
]
