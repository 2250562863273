import styled from 'styled-components'

export const BgContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
`
export const HeaderBody = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`
