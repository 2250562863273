import React from 'react'
import moment from 'moment'
import {
  UncontrolledPopover,
  Button,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap'

import { OverlayComponent, OverlayContainer } from './styles'

interface ScheduleProps {
  uuid: string
  day: string
  startTime: string
  endTime: string
  specialty: string
  // eslint-disable-next-line no-unused-vars
  handleDelete: (uuid: string) => void
  handleClick: () => void
}

const OverlaySchenduling = ({
  specialty,
  day,
  uuid,
  startTime,
  endTime,
  handleDelete,
  handleClick,
}: ScheduleProps) => (
  <OverlayContainer className="text-center">
    <OverlayComponent id={`PopoverLegacy-${uuid}`}>
      {specialty}
    </OverlayComponent>
    <UncontrolledPopover
      placement="bottom"
      target={`PopoverLegacy-${uuid}`}
      trigger="legacy"
    >
      <PopoverHeader>
        {`${moment(day).format(
          'dddd, DD/MM'
        )} - Das ${startTime} às ${endTime}`}
      </PopoverHeader>
      <PopoverBody style={{ width: '%100', justifyContent: 'center' }}>
        <Button
          id={`PopoverLegacy-${uuid}`}
          type="button"
          onClick={handleClick}
          outline
          hover="none"
        >
          Alterar Consulta
        </Button>
        <Button
          style={{ marginTop: '10px', marginLeft: '0px' }}
          id={`PopoverLegacy-${uuid}`}
          type="button"
          onClick={() => handleDelete(uuid)}
        >
          Cancelar
        </Button>
      </PopoverBody>
    </UncontrolledPopover>
  </OverlayContainer>
)
export default OverlaySchenduling
