import React, { useState, useEffect } from 'react'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import api from 'configs/api'

import { alertWarning, alertSuccess } from 'utils/toast'
import moment from 'moment'

import {
  deleteScheduling,
  changeScheduling,
  getDaysAVailable,
} from 'services/doctor'

import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import Loading from 'components/Loading'
import { ContentButtons } from './styles'

interface ScheduleProps {
  id: string
  // eslint-disable-next-line camelcase
  specialty_id: string
  // eslint-disable-next-line camelcase
  slot_id: string
  oldDay: string
  oldTime: string
  oldEndTime: string
  name: string
  newDay?: string
  newTime?: string
  quantity?: number
}

interface ModalProps {
  newScheduling: ScheduleProps
  doctorId: string
  // eslint-disable-next-line no-unused-vars
  setNewScheduling: (newScheduling: ScheduleProps) => void
  schedulings: any[]
  // eslint-disable-next-line no-unused-vars
  setSchedulings: (schedulings: any[]) => void
  // eslint-disable-next-line no-unused-vars
  setModalOpen: (modalOpen: boolean) => void
  getSchedulings: () => void
  modalType: 'Confirm' | 'Change' | 'Delete'
  // eslint-disable-next-line no-unused-vars
  setModalType: (modalOpen: 'Confirm' | 'Change' | 'Delete') => void
}

const screen = window.innerWidth

const AllModal = ({
  newScheduling,
  setNewScheduling,
  setModalOpen,
  schedulings,
  setSchedulings,
  getSchedulings,
  modalType,
  setModalType,
  doctorId,
}: ModalProps) => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(true)
  const [times, setTimes] = useState<String[]>([])
  const [select, setSelect] = useState<'day' | 'hour'>('day')
  setModalOpen(modal)
  const [availableDates, setAvailableDates] = useState<any>([])
  const [month, setMonth] = useState(moment(new Date()).format('YYYY-MM'))

  const handleSelectDay = async (info: any) => {
    setLoading(true)

    const res = await getDaysAVailable(
      doctorId,
      info.event.startStr,
      newScheduling?.specialty_id,
      Number(newScheduling?.quantity) > 1 ? '60' : ''
    )
    setLoading(false)
    setTimes(res.data)
    setNewScheduling({
      ...newScheduling,
      newDay: info.event.startStr,
    })
    setSelect('hour')
  }

  const handleSelectHour = (time: any) => {
    setNewScheduling({
      ...newScheduling,
      newTime: time.time,
      slot_id: time.slot_id,
    })
    setModalType('Confirm')
  }

  const cancelScheduling = async () => {
    setLoading(true)
    const res = await deleteScheduling(newScheduling.id)
    setLoading(false)
    setModal(false)
    if (res.status) {
      alertSuccess(res.msg)
      const oldSchedulings = schedulings.filter(
        (item: any) => item?.id !== newScheduling.id
      )
      setSchedulings(oldSchedulings)
    } else {
      alertWarning('Não foi possível cancelar o agendamento')
    }
  }
  const changeSchedulings = async () => {
    setLoading(true)
    const res = await changeScheduling(newScheduling.id, {
      slot_ids: newScheduling.slot_id,
    })
    setModalOpen(false)
    if (res.status) {
      alertSuccess(res.msg)
      getSchedulings()
      setLoading(false)
    } else {
      setLoading(false)
      alertWarning('Não foi possível alterar a data do agendamento')
    }
  }

  const filterAvailableDates = async () => {
    const dates: any[] = []
    setLoading(true)
    try {
      const result = await api.post(`schedulings/v2/verify-dates`, {
        specialty_id: newScheduling.specialty_id,
        scheduling_date: month,
        doctor_id: doctorId,
        time: Number(newScheduling?.quantity) > 1 ? '60' : '',
      })
      result?.data?.days?.forEach((item: any) => {
        const datas = {
          start: item,
          allDay: true,
          display: 'background',
          title: screen < 500 ? 'Hors. Disps.' : 'Horários disponiveis',
          id: item,
          areaName: 'item teste',
          areaId: newScheduling.specialty_id,
          doctorUuid: doctorId,
          color: '#ffbcce',
        }
        dates.push(datas)
      })
      setAvailableDates(dates)
    } catch (err) {
      console.log('err', err)
    }

    setLoading(false)
  }
  useEffect(() => {
    if (modalType === 'Change') {
      filterAvailableDates()
    }
  }, [month])
  const toggle = () => {
    setModal(!modal)
  }
  const ModalDeleteScheduling = () => (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>
          {`Deseja cancelar a consulta de ${
            newScheduling?.name
          } do dia ${moment(newScheduling?.oldDay).format('DD/MM')} às ${
            newScheduling?.oldTime
          }?`}
        </h3>
      </ModalHeader>
      <ModalBody className="p-4">
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => cancelScheduling()}
              outline
              hover="none"
            >
              Sim
            </Button>
            <Button onClick={() => setModal(!modal)}>Não</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
  const ModalChangeScheduling = () => (
    <Modal size={select === 'day' ? 'lg' : 'md'} isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {select === 'day' ? (
          <h3>Selecione uma data</h3>
        ) : (
          <div>
            {times.length !== 0 ? (
              <h3>Selecione um horário</h3>
            ) : (
              <h3>
                Não possui mais horários disponíveis para essa data, deseja
                escolher outra data?
              </h3>
            )}
          </div>
        )}
      </ModalHeader>
      <div style={{ padding: '0 15px', height: '100%' }}>
        {select === 'day' ? (
          <FullCalendar
            datesSet={(e: any) => {
              setMonth(moment(e?.startStr).format('YYYY-MM'))
            }}
            dayHeaders
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            locale={ptLocale}
            dayHeaderFormat={{
              weekday: screen < 500 ? 'short' : 'long',
            }}
            height={750}
            timeZone="America/Sao_Paulo"
            firstDay={1}
            titleFormat={{ month: 'short' }}
            events={availableDates}
            eventClick={(info: any) => {
              handleSelectDay(info)
            }}
            showNonCurrentDates={false}
          />
        ) : (
          <div>
            {times.length === 0 ? (
              <div>
                <ContentButtons>
                  <Button
                    style={{ width: '45%', flexWrap: 'wrap', margin: 5 }}
                    color="secondary"
                    onClick={() => setSelect('day')}
                  >
                    Sim
                  </Button>
                  <Button
                    style={{ width: '45%', flexWrap: 'wrap', margin: 5 }}
                    color="secondary"
                    onClick={() => setModal(!modal)}
                  >
                    Não
                  </Button>
                </ContentButtons>
              </div>
            ) : (
              <ContentButtons>
                {times.map((time: any) => (
                  <Button
                    style={{ width: 100, flexWrap: 'wrap', margin: 5 }}
                    key={time.slot_id}
                    color="secondary"
                    onClick={() => {
                      handleSelectHour(time)
                    }}
                  >
                    {time.time}
                  </Button>
                ))}
              </ContentButtons>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
  const ModalChangeConfimation = () => (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>
          {`Alterar consulta do ${newScheduling.name} do dia ${moment(
            newScheduling.oldDay
          ).format('DD/MM')} às ${newScheduling.oldTime} para o dia ${moment(
            newScheduling.newDay
          ).format('DD/MM')} às ${newScheduling.newTime}?`}
        </h3>
      </ModalHeader>
      <ModalBody className="p-4">
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => changeSchedulings()}
              outline
              hover="none"
            >
              Alterar
            </Button>
            <Button onClick={() => toggle()}>Cancelar</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      {modalType === 'Delete' && ModalDeleteScheduling()}
      {modalType === 'Confirm' && ModalChangeConfimation()}
      {modalType === 'Change' && ModalChangeScheduling()}
    </div>
  )
}
export default AllModal
