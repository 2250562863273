/* eslint-disable react/require-default-props */
import React from 'react'
import { Button } from 'reactstrap'
import PermissionComponent from 'components/PermissionComponent'
import moment from 'moment'
import './styles.css'

interface Props {
  title: string
  price?: string
  quantityPaid?: number
  unit?: number
  isReturned?: boolean
  returnSpecialty?: string
  remove?: boolean
  action?: () => void
  expired?: string
  country?: any
  currencySymbols?: any
}
const ScheduleCard = ({
  title,
  price = '',
  unit = 0,
  isReturned = false,
  returnSpecialty = '',
  remove = false,
  expired = '',
  quantityPaid = 0,
  country,
  action,
  currencySymbols,
}: Props) => (
  <div
    className="containerCard"
    style={
      unit !== 0
        ? { borderLeftColor: '#1AC68C', minHeight: 60, padding: '5px 15px' }
        : { borderLeftColor: '#ff3f72', height: 50, padding: 15 }
    }
  >
    <div>
      <h5 className="align-self-start">
        {`${title}${returnSpecialty ? ` - ${returnSpecialty}` : ''}${
          isReturned ? ' (Retorno)' : ''
        }`}
      </h5>
      {expired && (
        <h5 className="align-self-start">
          {`Expira em ${moment(expired).format('DD/MM/YYYY')}`}
        </h5>
      )}
      {quantityPaid !== 0 && <h5>{`Total de consultas: ${quantityPaid}`}</h5>}
    </div>

    <div className="container-price">
      {unit !== 0 && <span className="mr-1">{`${unit}x`}</span>}
      {price && <span>{`${currencySymbols[country] || 'R$'} ${price}`}</span>}

      <PermissionComponent role="SUPERADMIN,ADMIN">
        {remove && (
          <span style={{ marginLeft: 10 }}>
            <Button size="sm" sm="auto" color="primary" onClick={action}>
              <i className="far fa-trash-alt" />
            </Button>
          </span>
        )}
      </PermissionComponent>
    </div>
  </div>
)

export default ScheduleCard
