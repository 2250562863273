import React, { useMemo, useEffect } from 'react'
import * as yup from 'yup'

import { useFormik, FormikProvider } from 'formik'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
} from 'reactstrap'
import { alertDanger, alertSuccess } from 'utils/toast'
import useSpecialty from 'hooks/useSpecialty'

const schemaSpecialties = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  status: yup.string().required('Informe o status!'),
  time: yup.string().required('Informe o tempo!'),
})

const NewSpecialtyModal = ({
  modal,
  setModal,
  specialty,
  setSpecialty,
  setLoading,
}: any) => {
  const toggle = () => setModal(!modal)
  const { createSpecialty, updateSpecialty } = useSpecialty()

  const initialValues = useMemo(
    () => ({
      name: '',
      status: '',
      time: '',
    }),
    []
  )
  const saveSpecialty = async (datas: any) => {
    setLoading(true)
    const response = await updateSpecialty({
      uuid: specialty.id,
      name: datas.name,
      status: datas.status,
      attendanceTime: datas.attendanceTime,
    })
    if (response.status) {
      setLoading(false)
      alertSuccess('Especialidade atualizada com sucesso.')
    } else {
      setLoading(false)
      alertDanger(response.msg || 'Erro ao atualizar Especialidade.')
    }
    toggle()
    setSpecialty({})
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schemaSpecialties,
    onSubmit: async (values) => {
      setLoading(true)
      const statusValue = values.status === 'active' ? 'active' : 'inactive'
      const datas = {
        name: values.name,
        status: statusValue,
        attendanceTime: values.time,
      }
      if (specialty.id) {
        saveSpecialty(datas)
      } else {
        const { status, msg } = await createSpecialty(datas)

        if (status) {
          setLoading(false)
          alertSuccess('Cadastrada com sucesso')
          toggle()
        } else {
          setLoading(false)
          alertDanger(msg || 'Erro ao cadastrar Especialidade')
        }
      }
    },
  })

  useEffect(() => {
    if (specialty.id) {
      formik.setValues({
        name: specialty.name,
        status: specialty.statusDescription,
        time: specialty.attendanceTime,
      })
    } else {
      formik.setValues(initialValues)
    }
  }, [specialty])

  return (
    <div>
      <Button
        sm="3"
        color="secondary"
        onClick={() => {
          setSpecialty({})
          toggle()
        }}
      >
        <i className="fas fa-plus" />
        {` Cadastrar`}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3>
            {specialty.id ? 'Editar Especialidade' : 'Cadastrar Especialidade'}
          </h3>
        </ModalHeader>
        <ModalBody className="p-4">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Row className="d-flex">
                <Col>
                  <FormGroup>
                    <Label for="name">Nome da Especialidade</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      error={!!formik.errors.name}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div className="input-feedback">{formik.errors.name}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-1 d-flex justify-content-between align-items-center">
                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="status"> Status</Label>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      className="form-control-alternative"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option selected value="">
                        Selecione...
                      </option>
                      <option value="active">Ativo</option>
                      <option value="inactive">Inativo</option>
                    </Input>
                    {formik.errors.status && formik.touched.status && (
                      <div className="input-feedback">
                        {formik.errors.status}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="time"> Tempo</Label>
                    <Input
                      type="select"
                      name="time"
                      id="time"
                      className="form-control-alternative"
                      value={formik.values.time}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option selected value="">
                        Tempo de consulta...
                      </option>
                      <option value="15">15 min</option>
                      <option value="30">30 min</option>
                    </Input>
                    {formik.errors.time && formik.touched.time && (
                      <div className="input-feedback">{formik.errors.time}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    color="secondary"
                    disabled={!formik.isValid}
                    onClick={!formik.isValid ? toggle : () => {}}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
    </div>
  )
}
export default NewSpecialtyModal
