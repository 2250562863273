import React, { useEffect, useState } from 'react'
import { useAuth } from 'context/authentication/auth.context'
import { Redirect, Route } from 'react-router-dom'
import { getStorage } from 'utils/storage'

export const PrivateRoute = ({ props, ...rest }: any) => {
  const { userLogged } = useAuth()
  const [permissions, setPermissions] = useState(false)
  const [role, setRole] = useState('')

  useEffect(() => {
    const loadRole = () => {
      const response = getStorage('@ManagerStarbem:user')
      if (response) {
        const data = response
        const findRole = data?.roles.some((r: string) =>
          props.role?.split(',').includes(r)
        )
        setPermissions(findRole)
      }
    }
    loadRole()
    setRole(props.role)
    userLogged()
  }, [])

  if (!userLogged()) {
    return <Redirect to="/" />
  }
  if (!role && userLogged()) {
    return <Route {...rest} />
  }
  return permissions ? <Route {...rest} /> : <Redirect to="/" />
}
