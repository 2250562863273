import React, { useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import ComponentBack from 'components/ComponentBack'
import Loading from 'components/Loading'
import { useFormik, FormikProvider } from 'formik'
import api from 'configs/api'
import { alertWarning, alertSuccess } from 'utils/toast'
import * as yup from 'yup'
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Input,
  Label,
  Form,
  FormGroup,
  Button,
  Tooltip,
} from 'reactstrap'

const schemaRegister = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  amount: yup.string().required('Campo obrigatório'),
})

const NewPlans = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [specialtiesAll, setSpecialtiesAll] = useState<any[]>([])

  const { state }: any = useLocation()
  const newPlan = !state?.id

  const initialValues: any = useMemo(
    () => ({
      id: '',
      title: '',
      count_scheduling: '',
      number_nutritionist: '',
      number_psychology: '',
      dependents_quantity: '',
      amount: '',
      benefits: [],
      specialties: [],
      slug: '',
      description: '',
      ebook: '',
    }),
    []
  )

  const getSpecialties = async () => {
    try {
      const response = await api.get(
        '/doctors/v2/specialties?page=1&limit=100',
        {
          headers: {
            'accept-language': 'pt-BR',
          },
        }
      )

      const datas = response?.data?.specialties?.map((data: any) => ({
        id: data.id,
        name: data.name,
        status: data.status,
        attendanceTime: data.attendance_time,
        createdAt: data.created_at,
        activeB2c: data.active_b2c,
      }))
      if (newPlan) {
        setSpecialtiesAll(datas)
      } else {
        setSpecialtiesAll(
          datas.map((specialty: any) => ({
            ...specialty,
            checked: state.specialties.includes(String(specialty.id)),
          }))
        )
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const onChangeCheckedSpecialty = (id: string) => {
    const newDatas = specialtiesAll.map((specialty: any) => {
      if (specialty.id === id) {
        if (specialty.checked) {
          return {
            ...specialty,
            checked: false,
          }
        }
        return {
          ...specialty,
          checked: true,
        }
      }
      return specialty
    })
    setSpecialtiesAll(newDatas)
  }

  const checkedSpecialty = () =>
    specialtiesAll
      .map((specialty: any) => {
        if (specialty.checked) {
          return String(specialty.id)
        }
        return null
      })
      .filter((area: any) => !!area) || []

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      if (checkedSpecialty().length === 0) {
        alertWarning('Selecione pelo menos uma especialidade')
        return
      }
      setLoading(true)
      const data = {
        id: state?.id,
        title: values.title,
        count_scheduling: Number(values.count_scheduling || 0),
        number_nutritionist: Number(values.number_nutritionist || 0),
        number_psychology: Number(values.number_psychology || 0),
        dependents_quantity: Number(values.dependents_quantity || 0),
        amount: Number(values.amount.replace(',', '.')) || 0,
        benefits: values.benefits,
        specialties: checkedSpecialty(),
        slug: 'star-empresa',
        description: 'teste',
        ebook: values.ebook,
      }
      console.log(data)

      if (newPlan) {
        try {
          await api.post('/manager/plans', data)
          setLoading(false)
          formik.resetForm()
          alertSuccess('Plano cadastrado com sucesso')
        } catch (error) {
          setLoading(false)
          alertWarning('Não foi possível cadastrar o plano')
        }
      } else {
        try {
          await api.put(`manager/plans/${state?.id}`, data)
          setLoading(false)
          alertSuccess('Plano atualizado com sucesso!')
        } catch (err) {
          console.error('Erro ao atualizar plano:', err)
          setLoading(false)
          alertWarning(
            'Não foi possível atualizar o plano. Por favor, tente novamente mais tarde.'
          )
        }
      }
    },
  })

  useEffect(() => {
    getSpecialties()
    if (!newPlan) {
      const benefits: any = []

      if (state.chat_bot === 'active') {
        benefits.push('chat_bot')
      }
      if (state.starzen === 'active') {
        benefits.push('starzen')
      }
      if (state.check_emotional === 'active') {
        benefits.push('check_emotional')
      }

      formik.setValues({
        id: state?.id,
        title: state?.title,
        count_scheduling: state?.count_scheduling,
        number_nutritionist: state?.number_nutritionist,
        number_psychology: state?.number_psychology,
        dependents_quantity: state?.dependents_quantity,
        amount: state?.amount.replace(',', '.'),
        benefits,
        specialties: [],
        slug: 'star-empresa',
        description: 'teste',
        ebook: state?.ebook,
      })
    }
  }, [])

  return (
    <div>
      {loading && <Loading title="Carregando..." />}
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card>
          <CardHeader>
            <h2 className="mb-0 text-black">
              {newPlan
                ? 'Informe os dados do plano'
                : 'Editar os dados do plano'}
            </h2>
          </CardHeader>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <FormGroup>
                <Col className="mt-4">
                  <h4>Nome do plano</h4>
                  <Input
                    className="form-control-alternative"
                    placeholder="Digite o nome.."
                    name="title"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={formik.errors.title}
                    disabled={state?.title}
                  />
                  {formik.errors.title && formik.touched.title && (
                    <div className="input-feedback">{formik.errors.title}</div>
                  )}
                </Col>
              </FormGroup>
              <Col>
                <h4>Especialidade padrão</h4>
              </Col>
              <FormGroup check>
                {specialtiesAll?.map((specialty: any) =>
                  specialty.name !== 'Nutricionista' &&
                  specialty.name !== 'Psicólogo' ? (
                    <div key={specialty.id}>
                      <Col sm="3">
                        <Input
                          type="checkbox"
                          id={specialty.id}
                          name="specialties"
                          onBlur={formik.handleBlur}
                          onChange={() => {
                            formik.handleChange
                            onChangeCheckedSpecialty(specialty.id)
                          }}
                          checked={specialty.checked}
                          value={specialty.id}
                        />
                        <Label>{specialty.name}</Label>
                      </Col>
                    </div>
                  ) : null
                )}
              </FormGroup>

              <Col sm="4" className="mt-2">
                <Input
                  className="form-control-alternative"
                  placeholder="Qts. de consultas com especialidades padrão"
                  name="count_scheduling"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.count_scheduling}
                />
              </Col>
              <Col className="mt-4">
                <h4>Especialidades</h4>
              </Col>
              <Row>
                <div className="d-flex align-items-center">
                  <Col sm="2">
                    <FormGroup check>
                      <Input
                        className="form-control-alternative"
                        placeholder="Qtd."
                        name="number_nutritionist"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.number_nutritionist}
                      />
                    </FormGroup>
                  </Col>
                  <FormGroup check>
                    {specialtiesAll?.map((specialty: any) =>
                      specialty.name === 'Nutricionista' ? (
                        <React.Fragment key={specialty.id}>
                          <Input
                            type="checkbox"
                            id={specialty.id}
                            name="specialties"
                            onBlur={formik.handleBlur}
                            onChange={() => {
                              formik.handleChange
                              onChangeCheckedSpecialty(specialty.id)
                            }}
                            checked={specialty.checked}
                            value={specialty.id}
                          />
                          <Label>{specialty.name}</Label>
                        </React.Fragment>
                      ) : null
                    )}
                  </FormGroup>
                  <Col sm="2">
                    <FormGroup check>
                      <Input
                        className="form-control-alternative"
                        placeholder="Qtd."
                        name="number_psychology"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.number_psychology}
                      />
                    </FormGroup>
                  </Col>
                  <FormGroup check>
                    {specialtiesAll?.map((specialty: any) =>
                      specialty.name === 'Psicólogo' ? (
                        <React.Fragment key={specialty.id}>
                          <Input
                            type="checkbox"
                            id={specialty.id}
                            name="specialties"
                            onBlur={formik.handleBlur}
                            onChange={() => {
                              formik.handleChange
                              onChangeCheckedSpecialty(specialty.id)
                            }}
                            checked={specialty.checked}
                            value={specialty.id}
                          />
                          <Label>{specialty.name}</Label>
                        </React.Fragment>
                      ) : null
                    )}
                  </FormGroup>
                </div>
              </Row>
              <Col className="mt-4">
                <h4>Benefícios</h4>
              </Col>
              <div className="d-flex align-items-center">
                <Col sm="3" className="d-flex">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        name="benefits"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="check_emotional"
                        checked={formik.values.benefits.includes(
                          'check_emotional'
                        )}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                  <Label className="ml-2">Check-in emocional</Label>
                </Col>
                <Col sm="3" className="d-flex">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        name="benefits"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="starzen"
                        checked={formik.values.benefits.includes('starzen')}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                  <Label className="ml-2">Starzen</Label>
                </Col>
                <Col sm="3" className="d-flex">
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        name="benefits"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value="chat_bot"
                        checked={formik.values.benefits.includes('chat_bot')}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                  <Label className="ml-2">Chat bot</Label>
                </Col>
              </div>
              <div className="d-flex">
                <Col>
                  <FormGroup>
                    <h4>Quantidade de dependentes</h4>
                    <div className="d-flex align-items-center">
                      <Input
                        className="form-control-alternative"
                        placeholder="Digite a quantidade"
                        name="dependents_quantity"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.dependents_quantity}
                        disabled={!newPlan}
                        id="dependentsInput"
                      />
                      {!newPlan && (
                        <>
                          <i
                            className="fas fa-info-circle text-primary ml-2"
                            id="InfoIcon"
                            style={{ cursor: 'pointer' }}
                          />
                          <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target="InfoIcon"
                            toggle={() => setTooltipOpen(!tooltipOpen)}
                          >
                            Para alterar a quantidade de dependentes favor
                            entrar em contato com o time de produtos.
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <h4>Valor</h4>
                    <Input
                      className="form-control-alternative"
                      placeholder="Informe o valor"
                      name="amount"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                      error={formik.errors.amount}
                    />
                    {formik.errors.amount && formik.touched.amount && (
                      <div className="input-feedback">
                        {formik.errors.amount}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </div>
              <Row className="d-flex justify-content-end m-0 mb-4">
                <Col sm="auto">
                  <Button type="submit">
                    {newPlan ? 'Criar novo plano' : 'Atualizar plano'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </div>
  )
}

export default NewPlans
