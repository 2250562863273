/* eslint-disable */

import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Col,
  Button,
  CardBody,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardImg,
  Input,
  FormGroup,
  Alert,
} from 'reactstrap'
import { alertWarning, alertSuccess, alertDanger } from 'utils/toast'
import PermissionComponent from 'components/PermissionComponent'
import useSpecialty from 'hooks/useSpecialty'
import api from 'configs/api'
import Loading from 'components/Loading'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import moment from 'moment'
import profileImg from 'assets/img/brand/avatar.jpg'
import { formatName } from 'utils/formatName'

const ScheduleAppointment = ({ user, country }: any) => {
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('')
  const [reason, setReason] = useState<any>('')
  const [time, setTime] = useState<any>('')
  const [specialtyId, setSpecialtyId] = useState<any>({
    id: '',
    specialtie: '',
  })
  const [days, setDays] = useState([])
  const [doctors, setDoctors] = useState<any>([])
  const [newDays, setNewDays] = useState<any>('')
  const [selectTime, setSelectTime] = useState<any>('')
  const [selectDoctor, setSelectDoctor] = useState<any>({})
  const [month, setMonth] = useState(moment(new Date()).format('YYYY-MM'))
  const [showAlert, setShowAlert] = useState(false)

  const { getSpecialties, filterSpecialtiesActive } = useSpecialty()

  const toggle = () => {
    setModal(!modal)
    setState('')
    setReason('')
    setTime('')
    setDays([])
    setDoctors([])
    setNewDays([])
    setSelectTime('')
    setSelectDoctor({})
    setSpecialtyId({ id: '', specialtie: '' })
    setShowAlert(false)
  }

  const filterSpecialties = () => filterSpecialtiesActive()

  const getAllSpecialties = async () => {
    setLoading(true)
    await getSpecialties()
    setLoading(false)
  }

  const verifyDates = async () => {
    setLoading(true)
    try {
      const response = await api.post('schedulings/v2/verify-dates', {
        specialty_id: specialtyId.id,
        doctor_id: selectDoctor.id,
        scheduling_date: month,
        time: specialtyId.specialtie === 'Psicólogo' ? time : '30',
        country: country || 'BR',
      })

      setDays(
        response?.data?.days.map((dat: any) => ({
          start: dat,
          allDay: true,
          title: 'Hor. disp.',
          id: dat,
          display: 'background',
          backgroundColor: '#ffbcce',
        }))
      )
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
    setLoading(false)
  }

  const createSchedule = async () => {
    if (user?.status !== 'active') {
      alertWarning('Apenas usuários ativos podem agendar consultas.')
      return
    }

    setLoading(true)
    try {
      const response = await api.post(
        'schedulings/v3',
        {
          cellphone: user?.cellphone,
          slot_id: selectTime,
          symptoms: reason,
          user_id: user?.id,
        },
        {
          headers: {
            'Accept-Language': 'pt-BR',
          },
        }
      )

      if (response.data) {
        alertSuccess('Consulta agendada com sucesso.')
        toggle()
      } else {
        alertDanger('Erro ao agendar consulta.')
      }
    } catch (err: any) {
      alertDanger(err?.response?.data?.message || 'Erro ao agendar consulta.')
      setLoading(false)
    }
    setLoading(false)
  }

  const selectDay = async (day: string) => {
    setLoading(true)
    try {
      const { data } = await api.get(
        `schedulings/v3/verify-time?specialty_id=${specialtyId.id}&doctor_id=${selectDoctor.id}&scheduling_date=${day}&time=${time}`
      )

      setNewDays(data)
    } catch (err: any) {
      console.log(err)
    }
    setLoading(false)
  }

  const getDoctors = async () => {
    setLoading(true)
    try {
      const response = await api.get(
        `/doctors/v2/schedule/verify-doctors?specialty_id=${specialtyId.id}`
      )
      if (response?.data?.doctors.length > 0) {
        setDoctors(response?.data?.doctors)
      } else {
        alertWarning('Não há médicos disponíveis.')
        toggle()
      }
    } catch (err: any) {
      return {
        status: false,
        msg: err?.response?.data?.message,
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getAllSpecialties()
  }, [])

  const modalSchedule = () => (
    <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 600 }}>
      {loading && <Loading title="Aguarde..." />}
      {state === 'selectSpecialty' && (
        <>
          <ModalHeader className="text-center m-auto">
            <h2>Agendar consulta</h2>
            <h5 className="text-gray">
              Selecione a especialide na qual queira marcar a consulta
            </h5>
          </ModalHeader>
          <ModalBody>
            <Row className="mb-4">
              <Col>
                {filterSpecialties()?.map((item: any) => (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    key={item.id}
                    onClick={() => {
                      setSpecialtyId({ id: item.id, specialtie: item.name })
                    }}
                    active={item.id === specialtyId?.id}
                    className="mb-2"
                    style={{
                      width: window.innerWidth > 768 ? '47.5%' : '100%',
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  onClick={() => {
                    toggle()
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!specialtyId.id}
                  onClick={() => {
                    setState('selectDoctor')
                    getDoctors()
                  }}
                >
                  Próximo
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}

      {state === 'selectDoctor' && (
        <>
          <ModalHeader className="text-center m-auto">
            <h2>Agendar consulta</h2>
            <h5 className="text-gray">
              Selecione o profissional na qual queira marcar a consulta
            </h5>
            {specialtyId.specialtie === 'Psicólogo' && (
              <Col>
                <FormGroup>
                  <Input
                    type="select"
                    id="time"
                    name="time"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value)
                      setShowAlert(false)
                    }}
                  >
                    <option disabled value="" label="Tempo da consulta" />
                    <option value="30" label="30 minutos" />
                    <option value="60" label="60 minutos" />
                  </Input>
                </FormGroup>
                {showAlert && !time && (
                  <Alert color="warning">
                    <span className="alert-icon">
                      <i className="fa fa-exclamation-triangle" />
                    </span>
                    <span className="alert-text">
                      <strong>Aviso!</strong> Selecione o tempo para a consulta.
                    </span>
                  </Alert>
                )}
              </Col>
            )}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-4 ">
              <Col>
                {doctors?.map((doctor: any) => (
                  <Button
                    size="sm"
                    outline
                    color="primary"
                    key={doctor.id}
                    active={doctor.id === selectDoctor.id}
                    onClick={() => {
                      setSelectDoctor(doctor)
                      if (specialtyId.specialtie === 'Psicólogo' && !time) {
                        setShowAlert(true)
                      }
                    }}
                    className="mb-4 "
                    style={{
                      width: window.innerWidth > 768 ? '47.5%' : '100%',
                    }}
                  >
                    <CardImg
                      alt=""
                      src={doctor?.photo || `${profileImg}`}
                      style={{
                        borderRadius: '50%',
                        marginBottom: '.5rem',
                        width: '40px',
                        height: '40px',
                        marginTop: '-25px',
                        backgroundSize: 'auto',
                      }}
                    />
                    <h5
                      style={{
                        color: doctor.id === selectDoctor.id ? 'white' : '',
                      }}
                    >
                      {`${
                        doctor.gender === 'male' ? 'Dr.' : 'Dra.'
                      } ${formatName(doctor.name)}`}
                    </h5>
                    <h6
                      style={{
                        color: doctor.id === selectDoctor.id ? 'white' : 'gray',
                      }}
                    >
                      {specialtyId.specialtie}
                    </h6>
                  </Button>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  onClick={() => {
                    toggle()
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={
                    !selectDoctor?.id ||
                    (specialtyId.specialtie === 'Psicólogo' && !time)
                  }
                  onClick={() => {
                    verifyDates()
                    setState('selectDate')
                  }}
                >
                  Próximo
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}

      {state === 'selectDate' && (
        <>
          <ModalHeader className="text-center m-auto">
            <h2>Agendar consulta</h2>
            <h5 className="text-gray">
              Selecione o dia e o horário na qual queira marcar a consulta
            </h5>
          </ModalHeader>
          <ModalBody>
            <Row className="mb-4">
              <Col>
                <Card className="shadow">
                  <FullCalendar
                    datesSet={(e: any) => {
                      setMonth(moment(e?.startStr).format('YYYY-MM'))
                    }}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    locale={ptLocale}
                    dayHeaderFormat={{
                      weekday: 'short',
                    }}
                    height={450}
                    timeZone="America/Sao_Paulo"
                    firstDay={1}
                    titleFormat={{ year: 'numeric', month: 'short' }}
                    events={[...days]}
                    eventClick={(info: any) => selectDay(info.event.id)}
                    showNonCurrentDates={false}
                  />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="flex column mb-2">
                <h4>Escolha o horário</h4>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gap: '10px',
                  }}
                >
                  {newDays?.times
                    ?.sort((a: any, b: any) => {
                      const timeA = moment(a.time, 'HH:mm')
                      const timeB = moment(b.time, 'HH:mm')
                      return timeA.diff(timeB)
                    })
                    .map((it: any) => (
                      <Button
                        size="sm"
                        outline={selectTime !== it.slot_id}
                        color="primary"
                        key={it.slot_id}
                        style={{ width: '100%', borderRadius: 30 }}
                        onClick={() => setSelectTime(it.slot_id)}
                      >
                        {it.time}
                      </Button>
                    ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  onClick={() => {
                    toggle()
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={selectTime.length === 0}
                  onClick={() => {
                    setState('schedule')
                  }}
                >
                  Próximo
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}

      {state === 'schedule' && (
        <>
          <ModalHeader className="text-center m-auto">
            <h2>Agendar consulta</h2>
            <h5 className="text-gray">
              Para melhor entendimento da consulta, escrever o motivo dela
            </h5>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="mb-4">
                <h5>
                  Para facilitar o atendimento, conte para nós o motivo da
                  consulta
                </h5>
                <Input
                  className="form-control-alternative"
                  placeholder="Descreva brevemente os sintomas para conhecimento do profissional de saúde que irá realizar o atendimento"
                  id="input-bio"
                  name="bio"
                  type="textarea"
                  style={{ height: '200px' }}
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-between">
                <Button
                  type="submit"
                  color="primary"
                  outline
                  onClick={() => {
                    toggle()
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => {
                    createSchedule()
                  }}
                >
                  Agendar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}
    </Modal>
  )

  const handleScheduleClick = () => {
    if (user?.status !== 'active') {
      alertWarning('Apenas usuários ativos podem agendar consultas.')
    } else {
      toggle()
      setState('selectSpecialty')
    }
  }

  return (
    <Card className="shadow d-flex mb-4">
      {modalSchedule()}
      <CardHeader className="bg-transparent">
        <Col className="mb-0 p-0 d-flex justify-content-between">
          <h2 className="mb-0 text-black">Consulta</h2>
        </Col>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="mb-0 p-0">
            <PermissionComponent role="SUPERADMIN,ADMIN">
              <Col sm="auto" className="d-flex p-0">
                <Button
                  size="md"
                  type="submit"
                  color="primary"
                  outline
                  onClick={handleScheduleClick}
                >
                  Agendar consulta
                </Button>
              </Col>
            </PermissionComponent>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default ScheduleAppointment
