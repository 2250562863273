import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Card, CardHeader, Table, Row, Col, Button } from 'reactstrap'

import Loading from 'components/Loading'
import useSpecialty from 'hooks/useSpecialty'

import NewSpecialtyModal from './NewSpecialty'

const TabSettings = () => {
  const [loading, setLoading] = useState(false)
  const { getSpecialties, specialties } = useSpecialty()
  const [modal, setModal] = useState(false)
  const [specialty, setSpecialty] = useState<any>({})

  const getAllSpecialties = async () => {
    setLoading(true)
    await getSpecialties()
    setLoading(false)
  }

  useEffect(() => {
    getAllSpecialties()
  }, [])

  return (
    <Card className="shadow">
      {loading ? (
        <Loading title="Aguarde..." />
      ) : (
        <>
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <Col sm="9" className="mb-2">
                <h3 className="mb-0 text-black">Especialidades Cadastradas</h3>
              </Col>
              <Col sm="3" className="d-flex justify-content-end">
                <NewSpecialtyModal
                  setModal={setModal}
                  modal={modal}
                  setSpecialty={setSpecialty}
                  specialty={specialty}
                  setLoading={setLoading}
                />
              </Col>
            </Row>
          </CardHeader>
          <Row className="mt-0">
            <div className="col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">nome</th>
                      <th scope="col">Tempo</th>
                      <th scope="col">status</th>
                      <th scope="col">data-cadastro</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specialties.map((specialt) => (
                      <tr key={specialt.id}>
                        <th scope="row">{specialt.id}</th>
                        <td>{specialt.name}</td>
                        <td>{`${specialt.attendanceTime} min`}</td>
                        <td>
                          {specialt.status === 'active' ? 'Ativo' : 'Inativo'}
                        </td>

                        <td>
                          {moment(specialt.createdAt)
                            .utc()
                            .format('DD/MM/YYYY')}
                        </td>
                        <td>
                          <Button
                            style={{ maxWidth: '30%', minWidth: 40 }}
                            color="default"
                            size="sm"
                            onClick={() => {
                              setSpecialty(specialt)
                              setModal(true)
                            }}
                          >
                            <i className="ni ni-ruler-pencil" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </>
      )}
    </Card>
  )
}
export default TabSettings
