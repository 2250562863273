import React, { useState } from 'react'
import { NavLink as NavLinkRRD, Link, useHistory } from 'react-router-dom'

// Components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Collapse,
  Row,
  Col,
} from 'reactstrap'

import profileImg from 'assets/img/brand/avatar.jpg'
import { useAuth } from 'context/authentication/auth.context'
import PermissionComponent from 'components/PermissionComponent'

import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'

const Sidebar = (props: any) => {
  const [collapseOpen, setCollapseOpen] = useState(false)
  const { logout } = useAuth()
  const history = useHistory()
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data)
  }

  const closeCollapse = () => {
    setCollapseOpen(false)
  }

  const createLinks = (routes: any) =>
    routes.map((prop: any) => {
      if (prop.icon !== '') {
        return (
          <PermissionComponent key={prop.name} role={prop.role}>
            <NavItem>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
                style={{ marginLeft: 3.5 }}
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          </PermissionComponent>
        )
      }
      if (prop.image) {
        return (
          <PermissionComponent key={prop.name} role={prop.role}>
            <NavItem>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <img
                  className={prop.img}
                  src={prop.image}
                  alt=""
                  style={{
                    height: 16,
                    width: 16,
                    marginRight: 20,
                  }}
                />
                {prop.name}
              </NavLink>
            </NavItem>
          </PermissionComponent>
        )
      }
      if (prop.mui) {
        return (
          <PermissionComponent key={prop.name} role={prop.role}>
            <NavItem>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                {prop.mui === 'meditation' && (
                  <SelfImprovementIcon
                    style={{
                      marginRight: 16,
                    }}
                  />
                )}

                {prop.name}
              </NavLink>
            </NavItem>
          </PermissionComponent>
        )
      }

      return null
    })

  const { routes, logo } = props
  let navbarBrandProps
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    }
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    }
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark text-light"
      expand="lg"
      style={{ backgroundColor: '#221C35' }}
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-lg-none text-light">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={profileImg} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bem Vindo!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Meu Perfil</span>
              </DropdownItem>
              <PermissionComponent role="SUPERADMIN">
                <DropdownItem to="/admin/admin" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Administração</span>
                </DropdownItem>
              </PermissionComponent>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  logout()
                  history.push('/')
                }}
              >
                <i className="ni ni-user-run" />
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-lg-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
          {process.env.NODE_ENV === 'development' && (
            <>
              <hr className="my-3" />
              <h6 className="navbar-heading text-muted">Documentation</h6>
              <Nav className="mb-md-3" navbar>
                <NavItem>
                  <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">
                    <i className="ni ni-ui-04" />
                    Components
                  </NavLink>
                </NavItem>
              </Nav>
            </>
          )}
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Sidebar
