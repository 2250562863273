import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Row, Table } from 'reactstrap'

export interface healthPostProps {
  healthPost: {
    uuid: string
    id: string
    name: string
    cnes: string
    attendence: string
    return: string
    status: string
  }[]
  prefectureId: string
  prefectureName: string
}

const TableHealthPost = ({
  healthPost,
  prefectureId,
  prefectureName,
}: healthPostProps) => {
  const histoy = useHistory()

  const handlePost = () => {
    const namePref = prefectureName.toLocaleLowerCase().replace(' ', '-')
    histoy.push(`/admin/unidade/${namePref}/novo`, {
      prefectureId,
    })
  }

  const onClickPost = (health: any) => {
    histoy.push(
      `/admin/unidade/${health.name.toLocaleLowerCase().replace(' ', '-')}/${
        health.id
      }`,
      {
        name: health.name,
        cnes: health.cnes,
        uuidPost: health.id,
        prefectureId,
      }
    )
  }

  return (
    <>
      <hr style={{ marginTop: 0 }} />
      <Row className="align-items-center mt-4 d-flex justify-content-between">
        <Col sm="auto" className="mb-2">
          <h3 className="mb-0 text-black">Unidades</h3>
        </Col>
        <Col sm="auto" className="mb-2">
          <Button type="button" color="primary" onClick={handlePost}>
            Cadastrar Unidade Nova
          </Button>
        </Col>
      </Row>
      {healthPost.length !== 0 && (
        <Row className="align-items-center mt-4">
          <div className="col overflow-auto">
            <Table size="sm" hover>
              <thead>
                <tr>
                  <th scope="col">id</th>
                  <th scope="col">name</th>
                  <th scope="col">cnes</th>
                  <th scope="col">tipo de atendimento</th>
                  <th scope="col">Retorno</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {healthPost.map((health) => (
                  <tr
                    onClick={() => onClickPost(health)}
                    style={{ cursor: 'pointer' }}
                    key={health.id}
                  >
                    <th scope="row">{health.id}</th>
                    <td>{health.name}</td>
                    <td>{health.cnes}</td>
                    {health.attendence ? (
                      <td>
                        {health.attendence === 'whatsapp'
                          ? 'WhatsApp'
                          : 'Plataforma'}
                      </td>
                    ) : (
                      <td />
                    )}
                    <td>{health.return}</td>
                    <td>{health.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Row>
      )}
    </>
  )
}
export default TableHealthPost
