import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from 'configs/api'
// import moment from 'moment'

import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import ReactDatetime from 'react-datetime'
import { alertDanger } from 'utils/toast'

import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'
import moment from 'moment'

const Push = () => {
  const [notifications, setNotifications] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('0')
  const [dates, setDates] = useState({
    start: '',
    end: '',
  })

  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [page, setPage] = useState(1)
  const limit = 10

  const getNotifications = async (start = '', end = '') => {
    setLoading(true)
    try {
      const res = await api.get(
        `/manager/push?page=${page}&limit=${limit}&type=${type}&start=${start}&end=${end}`
      )

      const datas = res?.data?.notifications?.map(
        (not: any, index: number) => ({
          id: not.send_after + index,
          title: not.headings?.en,
          body: not.contents?.en,
          successful: not.successful,
          errored: not.errored,
          sendAfter: new Date(not.send_after * 1000) || '',
          status: not.completed_at ? 'Enviado' : 'Não enviado',
        })
      )

      setNumberOfPages(Math.ceil(res?.data?.total_count / limit))
      if (Math.ceil(res?.data?.total_count / limit) === 1) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setNotifications(datas)

      if (start) {
        setPaginationOn(false)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getNotifications()
  }, [page, type])

  const getByDates = async () => {
    const { start, end } = dates

    if (!start && !end) {
      return alertDanger('Preencha os períodos para realizar a busca')
    }

    await getNotifications(start, end)

    return true
  }

  return (
    <div>
      {loading && <Loading title="Carregando Notificações..." />}
      <CardHeader className="bg-transparent border-0">
        <Row className="align-items-center d-flex justify-content-between">
          <Col sm="3" className="mb-2">
            <h3 className="mb-0 text-black">Push Agendados ou Enviados</h3>
          </Col>
          {type === '0' && (
            <Col sm="auto" className="d-flex align-items-center">
              <FormGroup>
                <h6 className="mb-0 text-black">Data inicial</h6>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'Selecione a data inicial',
                      style: {
                        minWidth: 300,
                      },
                    }}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    value={dates.start}
                    onChange={(e: any) =>
                      setDates({
                        ...dates,
                        start: moment(e).format('YYYY-MM-DD'),
                      })
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="ml-2">
                <h6 className="mb-0 text-black">Data final</h6>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: 'Selecione a data final',
                      style: {
                        minWidth: 300,
                      },
                    }}
                    dateFormat="DD/MM/YYYY"
                    value={dates.end}
                    timeFormat={false}
                    onChange={(e: any) =>
                      setDates({
                        ...dates,
                        end: moment(e).format('YYYY-MM-DD'),
                      })
                    }
                  />
                </InputGroup>
              </FormGroup>
              <Col className="ml-3">
                <Button onClick={() => getByDates()} color="secondary">
                  <i className="fas fa-search" />
                </Button>
              </Col>
              <Col>
                <Button onClick={() => getNotifications()} color="primary">
                  <i className="far fa-trash-alt" />
                </Button>
              </Col>
            </Col>
          )}
        </Row>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Link to="/admin/notificacao/nova">
              <Button color="secondary">
                <i className="fas fa-plus" />
              </Button>
            </Link>
          </Col>
          <Col sm="3">
            <FormGroup className="mb-0">
              <Input
                type="select"
                style={{ paddingRight: 10 }}
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option value="0">Enviadas pelo Dashboard</option>
                <option value="1">Enviadas pela Api</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </CardHeader>

      <Row className="mt-0">
        <div className="col">
          <Card className="shadow">
            <Table className="align-items-center table-flush" responsive hover>
              <thead className="thead-light">
                <tr>
                  <th scope="col">titulo</th>
                  <th scope="col">corpo</th>
                  <th scope="col">enviados</th>
                  <th scope="col">não enviados</th>
                  <th scope="col">data envio</th>
                  <th scope="col">status</th>
                </tr>
              </thead>

              <tbody>
                {notifications?.map((notification) => (
                  <tr key={notification.id} style={{ cursor: 'pointer' }}>
                    <th scope="row">{notification.title}</th>
                    <td
                      style={{
                        minWidth: '300px',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {notification.body}
                    </td>
                    <td>{notification.successful}</td>
                    <td>{notification.errored}</td>
                    <td>
                      {moment(new Date(notification.sendAfter)).format(
                        'DD-MM-YYYY HH:mm'
                      )}
                    </td>
                    <td>{notification.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {paginationOn && (
              <PaginationComponent
                numberOfPages={numberOfPages}
                page={page}
                setPage={setPage}
              />
            )}
          </Card>
        </div>
      </Row>
    </div>
  )
}

export default Push
