export const translateSpecialtyName = (name: string): string => {
  const translations: { [key: string]: string } = {
    Psiquiatra: 'psychiatrist',
    Psicólogo: 'psychologist',
    'Ginecologista/Obstetra': 'gynecologist/obstetrician',
    Gastroenterologia: 'gastroenterologist',
    Endocrinologista: 'endocrinologist',
    Otorrino: 'otolaryngologist',
    Pneumo: 'pulmonologist',
    Hematologista: 'hematologist',
    'Clínico Geral': 'general practitioner',
    Geriatra: 'geriatrician',
    Vascular: 'vascular Surgeon',
    Ortopedista: 'orthopedist',
    Dermatologista: 'dermatologist',
    Nutrologia: 'nutritionist',
    Urologia: 'urologist',
    Pediatra: 'pediatrician',
    Neurologista: 'neurologist',
    Nutricionista: 'nutritionist',
    Cardiologista: 'cardiologist',
  }

  return translations[name] || name
}
