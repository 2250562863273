import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import useFetch from 'hooks/useFetch'

import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'

const Policy = () => {
  const history = useHistory()

  const { data, isLoading } = useFetch(`/backoffice/ms/v1/policies`)

  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              {isLoading ? (
                <Loading title="Aguarde..." />
              ) : (
                <>
                  <CardHeader className="d-flex justify-content-between">
                    <Col sm="9" className="mb-2">
                      <h3 className="mb-0 text-black">Políticas Cadastrados</h3>
                    </Col>
                  </CardHeader>
                  <Row className="mt-0">
                    <div className="col">
                      <Card className="shadow">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                          hover
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">id</th>
                              <th scope="col">aplicação</th>
                              <th scope="col">linguagem</th>
                              <th scope="col">data do cadastro</th>
                              <th scope="col">data da atualizacao</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((privacy: any) => (
                              <tr
                                key={privacy.id}
                                onClick={() => {
                                  history.push(
                                    `/admin/politica/${privacy.id}`,
                                    {
                                      privacy,
                                    }
                                  )
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <th scope="row">{privacy.id}</th>
                                <td>{privacy.application}</td>
                                <td>{privacy.lang}</td>
                                <td>
                                  {moment(privacy.created_at)
                                    .utc()
                                    .format('DD/MM/YYYY')}
                                </td>
                                <td>
                                  {moment(privacy.updated_at)
                                    .utc()
                                    .format('DD/MM/YYYY')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}
export default Policy
