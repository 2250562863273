/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  Button,
  Row,
  Container,
  Col,
  Table,
  Progress,
} from 'reactstrap'

import moment from 'moment'
import Loading from 'components/Loading'
import useFetch from 'hooks/useFetch'

const Queries = ({ select }: any) => {
  const [month, setMonth] = useState(new Date())

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')
  const getPorcent = (value: any, total: any) =>
    value !== '0' && total !== 0
      ? ((Number(value) / Number(total)) * 100).toFixed(1)
      : 0

  const { data, isLoading } = useFetch(
    `schedulings/ms/v1/reports/status?${dateFormated(month)}&country=${
      select.sigla
    }`
  )

  const changeDate = (value: any) => {
    setMonth(value)
  }

  return (
    <>
      {isLoading && <Loading title="Aguarde..." />}

      <Card className="shadow">
        <Container>
          <CardHeader className="mb-4">
            <Row className="justify-content-between">
              <h2>Consultas</h2>
              <div className="d-flex align-items-center">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  size="sm"
                  onClick={() => {
                    changeDate(moment(month).subtract(1, 'month'))
                  }}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
                  {moment(month).format('MMM YYYY')}
                </h4>
                <Button
                  className="btn-icon btn-2 ml-1"
                  color="primary"
                  size="sm"
                  disabled={verifyMonth}
                  onClick={() => {
                    changeDate(moment(month).add(1, 'month'))
                  }}
                >
                  <i className="fa fa-chevron-right" />
                </Button>
              </div>
            </Row>
          </CardHeader>
        </Container>
        <Container>
          <Card className="border-0">
            <Col className="ml-2">
              <CardHeader>
                <Row>
                  <h3>{select?.sigla ? `${select?.name}` : 'País'}</h3>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  <tr>
                    <th>Finalizadas</th>
                    <td>{data?.appointments?.finished}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="mr-2">
                          {`${getPorcent(
                            data?.appointments?.finished,
                            data?.appointments?.total
                          )}%`}
                        </span>
                        <div>
                          <Progress
                            max="100"
                            value={getPorcent(
                              data?.appointments?.finished,
                              data?.appointments?.total
                            )}
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Atendimentos Futuros</th>
                    <td>{data?.appointments?.pending}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="mr-2">
                          {`${getPorcent(
                            data?.appointments?.pending,
                            data?.appointments?.total
                          )}%`}
                        </span>
                        <div>
                          <Progress
                            max="100"
                            value={getPorcent(
                              data?.appointments?.pending,
                              data?.appointments?.total
                            )}
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Canceladas</th>
                    <td>{data?.appointments?.canceled}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="mr-2">
                          {`${getPorcent(
                            data?.appointments?.canceled,
                            data?.appointments?.total
                          )}%`}
                        </span>
                        <div>
                          <Progress
                            max="100"
                            value={getPorcent(
                              data?.appointments?.canceled,
                              data?.appointments?.total
                            )}
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>No-Show</th>
                    <td>{data?.appointments?.no_show}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="mr-2">
                          {`${getPorcent(
                            data?.appointments?.no_show,
                            data?.appointments?.total
                          )}%`}
                        </span>
                        <div>
                          <Progress
                            max="100"
                            value={getPorcent(
                              data?.appointments?.no_show,
                              data?.appointments?.total
                            )}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Card>
        </Container>
      </Card>
    </>
  )
}
export default Queries
