import React from 'react'
import './styles.css'

type IReactDropZoneInput = {
  children: any
  getRootProps: any
}

const ReactDropZoneInput: React.FC<IReactDropZoneInput> = ({
  children,
  getRootProps,
}) => (
  <div {...getRootProps} className="react-dropzone-input">
    {children}
  </div>
)

export default ReactDropZoneInput
