import React, { useMemo, useState } from 'react'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import api from 'configs/api'
import { useHistory } from 'react-router-dom'

// Components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'

import { alertDanger, alertSuccess } from 'utils/toast'
import Uf from 'utils/Uf'
import Loading from 'components/Loading'
import InputPassword from 'components/InputPassword'
import ComponentBack from 'components/ComponentBack'

const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  cpf: yup
    .string()
    .required('Informe o CPF!')
    .min(14, 'CPF inválido!')
    .test('test', 'CPF inválido!', (value) => {
      if (!value) return false
      return cpfValidator(value)
    }),
  password: yup
    .string()
    .required('Informe uma senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
  passwordConfirmation: yup
    .string()
    .required('Confirme a senha!')
    .oneOf([yup.ref('password'), null], 'As senhas devem corresponder'),
  cellphone: yup.string().required('Informe o telefone!'),
  gender: yup.string().required('Informe o gênero!'),
  birthdate: yup.string(),
  selectUf: yup.string().required('Selecione o Estado!'),
  numberDoc: yup.string().required('Informe o Número do Documento!'),
})
const genders = [
  {
    label: 'Masculino',
    key: 'Masculino',
  },
  {
    label: 'Feminino',
    key: 'Feminino',
  },
]
const NewNurse = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      cellphone: '',
      birthdate: '',
      cpf: '',
      selectUf: '',
      numberDoc: '',
      gender: '',
    }),
    []
  )
  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)

      const datas = {
        email: values.email,
        name: values.name,
        password: values.password,
        document: cpfCnpjUnmask(values.cpf),
        cellphone: telephoneUnmask(values.cellphone),
        gender: values.gender,
        birthdate: values.birthdate,
        professional_document_uf: values.selectUf,
        professional_document_number: values.numberDoc,
      }

      try {
        await api.post('/manager/nursings', datas)

        alertSuccess('Enfermeira cadastrada com sucesso!')
        history.push('/admin/enfermeiras')
      } catch (err: any) {
        alertDanger(err?.response?.data?.message)
      }
      setLoading(false)
    },
  })

  if (loading) return <Loading title="Aguarde..." />
  return (
    <>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-white shadow">
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Informe os dados abaixo para cadastrar uma nova enfermeira
                </h6>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-namedoctor"
                            >
                              Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-namedoctor"
                              placeholder="Digite o nome.."
                              name="name"
                              type="text"
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={(e) => formik.handleChange(e)}
                              value={formik.values.name}
                            />
                            {formik.errors.name && formik.touched.name && (
                              <div className="input-feedback">
                                {formik.errors.name}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Digite o email..."
                              type="text"
                              name="email"
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <div className="input-feedback">
                                {formik.errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-birthdate"
                            >
                              Data de nascimento
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-birthdate"
                              placeholder=""
                              type="text"
                              name="birthdate"
                              maxLength={10}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={dateMask(formik.values.birthdate)}
                            />
                            {formik.errors.birthdate &&
                              formik.touched.birthdate && (
                                <div className="input-feedback">
                                  {formik.errors.birthdate}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              placeholder="000.000.000-00"
                              type="text"
                              name="cpf"
                              maxLength={14}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={cpfCnpjMask(formik.values.cpf)}
                            />
                            {formik.errors.cpf && formik.touched.cpf && (
                              <div className="input-feedback">
                                {formik.errors.cpf}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cellphone"
                            >
                              Celular
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cellphone"
                              placeholder="Digite o Celular..."
                              type="text"
                              name="cellphone"
                              maxLength={15}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={telephoneMask(formik.values.cellphone)}
                            />
                            {formik.errors.cellphone &&
                              formik.touched.cellphone && (
                                <div className="input-feedback">
                                  {formik.errors.cellphone}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero-doc"
                            >
                              Número documento
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="numberDoc"
                              placeholder=""
                              type="text"
                              name="numberDoc"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.numberDoc}
                            />
                            {formik.errors.numberDoc &&
                              formik.touched.numberDoc && (
                                <div className="input-feedback">
                                  {formik.errors.numberDoc}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label htmlFor="select-uf">UF</label>

                            <Input
                              type="select"
                              id="selectUf"
                              name="selectUf"
                              value={formik.values.selectUf}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option defaultValue="" value="">
                                Selecione...
                              </option>
                              {Uf.map((e) => (
                                <option key={e} value={e} label={e} />
                              ))}
                            </Input>
                            {formik.errors.selectUf &&
                              formik.touched.selectUf && (
                                <div className="input-feedback">
                                  {formik.errors.selectUf}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label htmlFor="gender">Gênero</label>

                            <Input
                              type="select"
                              id="gender"
                              name="gender"
                              value={formik.values.gender}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option defaultValue="" value="">
                                Gênero...
                              </option>
                              {genders.map((e) => (
                                <option
                                  key={e.key}
                                  value={e.label}
                                  label={e.label}
                                />
                              ))}
                            </Input>
                            {formik.errors.gender && formik.touched.gender && (
                              <div className="input-feedback">
                                {formik.errors.gender}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <label
                                className="form-control-label"
                                htmlFor="input-password"
                              >
                                Senha
                              </label>
                            </div>
                            <InputPassword
                              className="form-control-alternative"
                              id="input-password"
                              placeholder=""
                              name="password"
                              autoComplete="new-password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            {formik.errors.password &&
                              formik.touched.password && (
                                <div className="input-feedback">
                                  {formik.errors.password}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="passwordConfirmation"
                            >
                              Confirmar senha
                            </label>
                            <InputPassword
                              className="form-control-alternative"
                              id="passwordConfirmation"
                              placeholder=""
                              name="passwordConfirmation"
                              type="password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.passwordConfirmation}
                            />
                            {formik.errors.passwordConfirmation &&
                              formik.touched.passwordConfirmation && (
                                <div className="input-feedback">
                                  {formik.errors.passwordConfirmation}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-end">
                        <Col lg="3">
                          <Button
                            color="warning"
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Cadastrar
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NewNurse
