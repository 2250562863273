import api from 'configs/api'
import { cpfCnpjUnmask } from 'js-essentials-functions'

export const getDatasPrefecture = async (uuid: string) => {
  try {
    const response = await api.get(`/manager/prefecture/${uuid}`)
    const { item } = response.data

    const dataPrefecture = {
      legalName: item?.legal_name,
      legalNature: item?.legal_nature,
      cnpj: item?.national_registration,
    }
    const datasPost = item?.healthPosts?.map((data: any) => ({
      uuid: data.uuid,
      name: data.name,
      id: data.id,
      cnes: data.cnes,
      attendence: data.attendence,
      return: data.return ? 'Sim' : 'Não',
      status: data.status === 'active' ? 'Ativo' : 'Inativo',
    }))
    const medicosInclusos = item?.doctors?.map((doc: any) => ({
      id: doc.id,
      uuid: doc.uuid,
      name: doc.name,
      email: doc.email,
      document: doc.document,
    }))

    return {
      status: true,
      dataPrefecture,
      datasPost,
      medicosInclusos,
      areasId: item.specialties,
    }
  } catch (err: any) {
    return {
      status: false,
      message: err.response.data.message,
    }
  }
}

interface CreatePrefectureProps {
  cnpj: string
  legalName: string
  legalNature: string
  doctorsId: string
  areasId: string
}

export const createPrefecture = async ({
  cnpj,
  legalName,
  legalNature,
  doctorsId,
  areasId,
}: CreatePrefectureProps) => {
  try {
    await api.post('/manager/prefecture', {
      national_registration: cpfCnpjUnmask(cnpj),
      legal_name: legalName,
      legal_nature: legalNature,
      doctor_id: doctorsId,
      area_id: areasId,
    })
    return {
      status: true,
    }
  } catch (err) {
    console.log(err)
    return {
      status: false,
    }
  }
}
interface UpdatePrefectureProps {
  uuid: string
  datas: {
    cnpj: string
    legalName: string
    legalNature: string
    doctorsId: string
    areasId: string
  }
}

export const updatePrefecture = async ({
  uuid,
  datas,
}: UpdatePrefectureProps) => {
  try {
    await api.put(`/manager/prefecture/${uuid}`, {
      national_registration: cpfCnpjUnmask(datas.cnpj),
      legal_name: datas.legalName,
      legal_nature: datas.legalNature,
      doctor_id: datas.doctorsId,
      area_id: datas.areasId,
    })
    return {
      status: true,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}

export const getAvailableDoctors = async (
  uuid: string,
  page: number,
  document: string
) => {
  try {
    const response = await api.get(
      `/manager/prefecture/available-doctors/${uuid}?page=${page}&document=${document}`
    )
    const availableDoctors = response?.data?.items?.map((doc: any) => ({
      id: doc.id,
      uuid: doc.uuid,
      name: doc.name,
      // document: doc.document,
      email: doc.email,
    }))
    return {
      status: true,
      availableDoctors,
      total: response?.data?.total,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}
