import React, { useState } from 'react'
import { Input } from 'reactstrap'
import './styles.scss'

const InputPassword = ({ ...rest }) => {
  const [isPassword, setIsPassword] = useState(false)

  return (
    <div className="input-password">
      <Input {...rest} type={isPassword ? 'text' : 'password'} />

      <button
        type="button"
        onClick={() => {
          setIsPassword(!isPassword)
        }}
      >
        {isPassword ? (
          <i className="fas fa-eye" style={{ color: '#aaa' }} />
        ) : (
          <i className="fas fa-eye-slash" style={{ color: '#aaa' }} />
        )}
      </button>
    </div>
  )
}

export default InputPassword
