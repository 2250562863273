import React from 'react'
import {
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'

interface PaginationProps {
  numberOfPages: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const PaginationComponent = ({
  numberOfPages,
  page,
  setPage,
}: PaginationProps) => {
  function arrayPage() {
    if (numberOfPages === 2) {
      if (page === 1) {
        return [page, page + 1]
      }
      if (page === numberOfPages) {
        return [page - 1, page]
      }
    }
    if (numberOfPages === 1) {
      return [page]
    }
    if (page === 1) {
      return [page, page + 1, page + 2]
    }
    if (page === numberOfPages) {
      return [page - 2, page - 1, page]
    }
    return [page - 1, page, page + 1]
  }

  return (
    <CardFooter className="py-4">
      {numberOfPages <= 1 ? (
        <div />
      ) : (
        <nav aria-label="...">
          <Pagination
            className="page pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
            sm="4"
          >
            <PaginationItem>
              <PaginationLink
                first
                disabled={page <= 1}
                onClick={() => setPage(1)}
                type="button"
              />
            </PaginationItem>
            <PaginationItem disabled={page <= 1}>
              <PaginationLink
                onClick={() => {
                  setPage(page - 1)
                }}
                type="button"
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
            {arrayPage().map((pag) => (
              <PaginationItem active={pag === page} key={pag}>
                <PaginationLink onClick={() => setPage(pag)} type="button">
                  {pag}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={page >= numberOfPages}>
              <PaginationLink onClick={() => setPage(page + 1)} type="button">
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                last
                disabled={page >= numberOfPages}
                onClick={() => setPage(numberOfPages)}
                type="button"
              />
            </PaginationItem>
          </Pagination>
        </nav>
      )}
    </CardFooter>
  )
}

export default PaginationComponent
