import React, { useCallback, useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Input,
  Button,
  Media,
  Form,
} from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { alertWarning, alertSuccess } from 'utils/toast'
import { useFormik, FormikProvider } from 'formik'

import api from 'configs/api'

const schemaRegister = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  category: yup.string().required('Campo obrigatório'),
})

const ModalStarzen: React.FC<any> = ({
  modal,
  setModal,
  setLoading,
  getAudios,
  editAudio,
  setEditAudio,
}) => {
  const [fileAudio, setFileAudio] = useState<any>(null)
  const [fileImage, setFileImage] = useState<any>()
  const [audioDuration, setAudioDuration] = useState<any>(0)
  const [categories, setCategories] = useState([]) // Estado para armazenar a lista de categorias

  const initialValues = useMemo(
    () => ({
      time: '',
      category: '',
      name: '',
    }),
    []
  )

  function formatTime(duration: any) {
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration % 60)
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${formattedMinutes}:${formattedSeconds}`
  }

  const getAudioFileDuration = (audioFile: any) => {
    const audioElement = new Audio()
    audioElement.src = URL.createObjectURL(audioFile)
    audioElement.onloadedmetadata = () => {
      setAudioDuration(formatTime(audioElement.duration))
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setFileImage(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop,
  })

  const onDropAudio = useCallback((acceptedFiles) => {
    setFileAudio(acceptedFiles[0])
    getAudioFileDuration(acceptedFiles[0])
  }, [])

  const { getRootProps: getRootPropsAudio, getInputProps: getInputPropsAudio } =
    useDropzone({
      accept: ['audio/*'],
      onDrop: onDropAudio,
    })

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const data = new FormData()
      // eslint-disable-next-line no-underscore-dangle
      if (editAudio?._id) {
        data.append('image', fileImage)
        data.append('time', values.time)
        data.append('categoryId', values.category)
        data.append('name', values.name)

        try {
          // eslint-disable-next-line
          await api.put(`/manager/starzen/${editAudio._id}`, data)
          getAudios()
          setEditAudio('')
          formik.resetForm()
          setFileImage(null)
          setFileAudio(null)
          setModal(!modal)
          alertSuccess('Áudio alterado com sucesso.')
        } catch (err: any) {
          alertWarning('ERRO ao editar o áudio')
        }
      } else if (!fileImage || !fileAudio) {
        setLoading(false)
        alertWarning(
          fileImage === null
            ? 'A imagem é obrigatória'
            : 'O áudio é obrigatório'
        )
      } else {
        data.append('image', fileImage)
        data.append('audio', fileAudio)
        data.append('time', audioDuration)
        data.append('categoryId', values.category)
        data.append('name', values.name)
        await api.post('/manager/starzen', data)

        getAudios()
        setEditAudio('')
        formik.resetForm()
        setFileImage(null)
        setFileAudio(null)
        setModal(!modal)
        alertSuccess('Áudio criado com sucesso!')
      }

      setLoading(false)
    },
  })

  const toggle = () => {
    formik.resetForm()
    setFileImage(null)
    setFileAudio(null)
    setEditAudio(null)
    setAudioDuration(0)
    setModal(!modal)
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (editAudio?._id) {
      formik.setValues({
        time: editAudio.time,
        category: editAudio.category.id ? editAudio.category.id : '',
        name: editAudio.name,
      })
    }
  }, [editAudio])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/manager/starzen/audios/categories')

        setCategories(response.data)
      } catch (error) {
        console.error('Erro ao buscar categorias:', error)
      }
    }
    if (modal) {
      fetchCategories()
    }
  }, [modal])

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>
          <div className="text-center justify-center">
            <h2 className="text-uppercase text-center">
              {
                // eslint-disable-next-line
                editAudio?._id ? 'Editar áudio' : 'Adicionar áudio'
              }
            </h2>
            <p className="font-weight-bold text-muted">
              {
                // eslint-disable-next-line
                editAudio?._id
                  ? 'Faça a edição do tema, nome e imagem do áudio.'
                  : 'Selecione ou arraste o arquivo .mp3, depois insira o tema, nome e imagem do áudio.'
              }
            </p>
          </div>
        </ModalHeader>
        <ModalBody style={{ paddingTop: '0' }}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <input {...getInputPropsAudio()} />
              {fileAudio || editAudio?.url ? (
                <Media className=" align-items-center justify-content-center">
                  <audio
                    src={
                      editAudio?.url
                        ? editAudio?.url
                        : URL.createObjectURL(fileAudio)
                    }
                    controls
                  >
                    <track kind="captions" src="" label="Portuguese" />
                  </audio>
                </Media>
              ) : (
                <Media
                  {...getRootPropsAudio()}
                  style={{
                    width: '450px',
                    height: '160px',
                    padding: '40px 0px 0px 0px',
                    gap: '10px',
                    borderRadius: '10px',
                    border: '1px dashed #dee2e6',
                    opacity: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#F2F4F7',
                  }}
                  className="align-items-center justify-content-center"
                >
                  {fileAudio || editAudio?.url ? (
                    <div style={{ textAlign: 'center' }}>
                      <audio
                        src={
                          editAudio?.url
                            ? editAudio?.url
                            : URL.createObjectURL(fileAudio)
                        }
                        controls
                      >
                        <track kind="captions" src="" label="Portuguese" />
                      </audio>
                      <Button
                        color="link"
                        style={{ marginTop: '10px' }}
                        onClick={() => setFileAudio(null)}
                      >
                        Substituir áudio
                      </Button>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      Arraste e solte o arquivo .MP3 aqui, ou clique para
                      selecioná-lo
                    </div>
                  )}
                </Media>
              )}

              <Row className="align-items-center mt-4">
                <Col sm="auto" className="d-flex align-items-center">
                  <Label style={{ marginRight: '4px' }}>Selecione o tema</Label>
                  <Input
                    type="select"
                    style={{ width: '320px' }}
                    name="category"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    error={formik.errors.category}
                  >
                    <option value="">Selecione</option>

                    {categories.map((category: any) => (
                      // eslint-disable-next-line no-underscore-dangle
                      <option key={category?._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col className="justify-content-center">
                  {formik.errors.category && formik.touched.category && (
                    <div className="input-feedback">
                      {formik.errors.category}
                    </div>
                  )}
                </Col>
              </Row>
              <Col className="d-flex align-items-center">
                <Row>
                  <Row className="mt-4">
                    <Col sm="12">
                      <Label>Nome do áudio</Label>
                      <Input
                        placeholder="Informe o nome ..."
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={formik.errors.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div className="input-feedback">
                          {formik.errors.name}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm="12">
                      <Label>Tempo</Label>
                      <Input
                        placeholder="00:00"
                        readOnly
                        name="time"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={audioDuration || formik.values.time}
                        error={formik.errors.time}
                        style={{ background: '#F2F4F7' }}
                      />
                    </Col>
                  </Row>
                </Row>
                <Col sm="7" className="text-center mt-4">
                  <Label>Imagem</Label>
                  <input {...getInputProps()} />
                  <Media
                    {...getRootProps()}
                    style={{
                      position: 'relative',
                      width: '236px',
                      height: '134px',
                      padding: '40px 0px 0px 0px',
                      gap: '10px',
                      borderRadius: '10px',
                      border: '1px dashed #dee2e6',
                      opacity: '1',
                      background: '#F2F4F7',
                      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                      overflow: 'hidden',
                    }}
                    className="position-relative"
                  >
                    {fileImage || editAudio?.image ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '120%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '-10%',
                        }}
                      >
                        <img
                          src={
                            editAudio?.image && !fileImage
                              ? editAudio?.image
                              : URL.createObjectURL(fileImage)
                          }
                          alt="Imagem do audio"
                          style={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <p style={{ margin: '0', fontSize: '14px' }}>
                          Tamanho (1080 x 1920)
                        </p>
                      </div>
                    )}
                  </Media>
                </Col>
              </Col>
              <Row className="mt-4">
                <Col sm="6">
                  <Button outline color="primary" onClick={toggle}>
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      !editAudio?.name &&
                      (!formik.values.name ||
                        !formik.values.category ||
                        !fileImage ||
                        !fileAudio)
                    }
                    title={
                      !editAudio?.name &&
                      (!formik.values.name ||
                        !formik.values.category ||
                        !fileImage ||
                        !fileAudio)
                        ? 'Todos os campos devem ser preenchidos para liberar o botão'
                        : undefined
                    }
                  >
                    {editAudio?.name ? 'Alterar' : 'Adicionar'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalStarzen
