import styled from 'styled-components'

export const OverlayComponent = styled.button`
  text-align: center;
  height: 100%;
  width: 100%;
  z-index: 1;
`

export const OverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;
`

export const OverlayContainerChange = styled.div`
  height: 0px;
  width: 0px;
`
export const ContentButtons = styled.div`
  background: #fff;
  display: flex;
  margin: 20px auto;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
`
