import React, { useMemo, useState, useEffect } from 'react'
import api from 'configs/api'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap'
import InputPassword from 'components/InputPassword'
import Loading from 'components/Loading'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { alertSuccess, alertWarning } from 'utils/toast'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { useAuth } from 'context/authentication/auth.context'

const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email válido!'),
  password: yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
})

const Profile = () => {
  const [profileEditable, setProfileEditable] = useState(false)
  const { adminData } = useAuth()
  const [loading, setLoading] = useState(false)

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      if (!formik.dirty) {
        alertWarning('Nenhuma alteração foi feita!')
        return
      }

      const datas: { email: string; name: string; password?: string } = {
        email: values.email,
        name: values.name,
      }

      if (values.password) {
        datas.password = values.password
      }

      setLoading(true)
      try {
        const response = await api.put(
          `/backoffice/ms/v1/user/${adminData.id}`,
          datas
        )
        alertSuccess(response.data.message)
        setProfileEditable(false)
      } catch (e: any) {
        alertWarning(
          e.response?.data?.message || 'Não foi possível atualizar o perfil'
        )
      }
      setLoading(false)
    },
  })

  useEffect(() => {
    formik.setValues({
      name: adminData.name,
      email: adminData.email,
      password: '',
      confirmPassword: '',
    })
  }, [adminData])

  if (loading) return <Loading title="Atualizando dados" />

  return (
    <>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-center justify-content-center d-flex">
          <Col lg="8">
            <Card className="bg-white shadow">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <CardHeader className="bg-white border-0 text-center">
                    <h3 className="mt-3 mb-3">Dados pessoais</h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '120px',
                        height: '120px',
                        borderRadius: '50%',
                        backgroundColor: '#f0f0f0',
                        margin: 'auto',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="black"
                        width="60px"
                        height="60px"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12c2.7 0 5-2.3 5-5s-2.3-5-5-5-5 2.3-5 5 2.3 5 5 5zm0 2c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z" />
                      </svg>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            disabled={!profileEditable}
                          />
                          {formik.errors.name && formik.touched.name && (
                            <div className="input-feedback">
                              {formik.errors.name}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            disabled={!profileEditable}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div className="input-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr className="my-4" />

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            Nova senha
                          </label>
                          <InputPassword
                            className="form-control-alternative"
                            id="input-password"
                            name="password"
                            autoComplete="new-password"
                            placeholder="Digite sua nova senha"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            disabled={!profileEditable}
                          />
                          {formik.errors.password &&
                            formik.touched.password && (
                              <div className="input-feedback">
                                {formik.errors.password}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirm-password"
                          >
                            Confirme a nova senha
                          </label>
                          <InputPassword
                            className="form-control-alternative"
                            id="input-confirm-password"
                            name="confirmPassword"
                            autoComplete="new-password"
                            placeholder="Confirme sua nova senha"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            disabled={!profileEditable}
                          />
                          {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword && (
                              <div className="input-feedback">
                                {formik.errors.confirmPassword}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="justify-content-center mt-4">
                      <Col sm="auto">
                        <Button
                          color="primary"
                          onClick={() => setProfileEditable(!profileEditable)}
                          disabled={!formik.dirty || !formik.isValid}
                          type={!profileEditable ? 'submit' : 'button'}
                        >
                          {profileEditable ? 'Salvar alterações' : 'Editar'}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </FormikProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Profile
