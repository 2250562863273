import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import api from 'configs/api'
import { cpfCnpjMask } from 'js-essentials-functions'

import { Table, Container, Row, Col, Button, Input, Tooltip } from 'reactstrap'
import PaginationComponent from 'components/PaginationComponent'
import Loading from 'components/Loading'

interface CompaniesProps {
  uuid: string
  id: string
  logo: string
  name: string
  cnpj: string
  status: string
  total: number
}

const Companies = () => {
  const [companies, setCompanies] = useState<CompaniesProps[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState('')

  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null)

  const toggleTooltip = (id: string) => {
    setVisibleTooltip(visibleTooltip === id ? null : id)
  }

  const history = useHistory()

  // Paginação
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)

  const onClickCompany = useCallback((company: CompaniesProps) => {
    history.push(`/admin/empresa/${company.id}`, {
      id: company.id,
    })
  }, [])

  const getCompanies = async (nameCompany = name, numberPage = page) => {
    setLoading(true)

    try {
      const response = await api.get(
        `/company/ms/v1/companies?page=${numberPage}&filter=${nameCompany}`
      )

      const datas = response.data.companies.map((data: any) => ({
        id: data.id,
        name: data.legal_name,
        status: data.status,
        cnpj: data.national_registration,
        total: data.customers,
      }))

      setNumberOfPages(Math.ceil(response.data.total / response.data.limit))
      setCompanies(datas)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCompanies()
  }, [page])

  return (
    <div>
      {loading && <Loading title="Carregando Empresas..." />}
      <Container className="mt-3" fluid>
        <Row className="align-items-center">
          <h3 className="mb-0 text-black">Empresas Cadastradas</h3>
        </Row>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Link to="/admin/empresa/novo">
              <Button color="secondary">
                <i className="fas fa-plus" />
              </Button>
            </Link>
          </Col>
          <Col sm="6" className="d-flex justify-content-end">
            <Col xs="8">
              <Input
                type="text"
                id="filtrar-name"
                value={name}
                placeholder="Filtrar pelo nome ou cnpj"
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                id="filter"
                onClick={() => {
                  getCompanies(name, 1)
                  setPage(1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
              <Tooltip
                isOpen={visibleTooltip === 'filter'}
                target="filter"
                toggle={() => toggleTooltip('filter')}
              >
                Filtrar empresa
              </Tooltip>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                id="ex_filter"
                onClick={() => {
                  getCompanies('', 1)
                  setName('')
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
              <Tooltip
                isOpen={visibleTooltip === 'ex_filter'}
                target="ex_filter"
                toggle={() => toggleTooltip('ex_filter')}
              >
                Apagar filtro
              </Tooltip>
            </Col>
          </Col>
        </Row>
        <Row className="mt-3">
          <Table
            className="align-items-center table-flush"
            responsive
            hover
            fluid="true"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">id</th>
                <th scope="col">nome</th>
                <th scope="col">cnpj</th>
                <th scope="col">total usuários</th>
                <th scope="col">status</th>
              </tr>
            </thead>

            <tbody>
              {companies?.map((company) => (
                <tr
                  key={company.id}
                  onClick={() => onClickCompany(company)}
                  style={{ cursor: 'pointer' }}
                >
                  <th
                    style={{
                      width: '25%',
                    }}
                  >
                    {company.id}
                  </th>
                  <td>{company.name}</td>
                  <td>{cpfCnpjMask(company.cnpj)}</td>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {company.total}
                  </td>
                  <td>
                    {company.status === 'active' && 'Ativo'}
                    {company.status === 'deleted' && 'Excluido'}
                    {company.status === 'inactive' && 'Inativo'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Col className="align-items-end">
            <PaginationComponent
              numberOfPages={numberOfPages}
              page={page}
              setPage={setPage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Companies
