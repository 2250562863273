import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Input,
  Button,
  Form,
} from 'reactstrap'
import { useFormik } from 'formik'
import api from 'configs/api'
import { alertWarning, alertSuccess } from 'utils/toast'

interface ModalTemasProps {
  modal: boolean
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  getTemas: () => void
  setThemeEdit: any
  theme: any
}

const schemaRegister = yup.object().shape({
  name: yup
    .string()
    .required('Campo obrigatório')
    .max(50, 'Máximo de 50 caracteres permitidos'),
  icon: yup.string().required('A seleção de um ícone é obrigatória'),
})
const iconUrls = [
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Balance.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Chakras.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Group.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Guru.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Meditation.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Mindfulness.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Namaste.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Nature.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Schedule.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Breathing.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Stress%20management.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Yoga%20instructor.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Yoga%20instructor1.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Yoga.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Yoga1.png',
  'https://starbem-production.nyc3.digitaloceanspaces.com/starzen-icons/Yoga2.png',
]

const ModalTemas: React.FC<ModalTemasProps> = ({
  modal,
  setModal,
  setLoading,
  getTemas,
  theme = null,
  setThemeEdit,
}) => {
  const [selectedIcon, setSelectedIcon] = useState<string>(theme?.icon || '')

  const initialValues = { name: theme?.name || '', icon: theme?.icon || '' }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const data = { name: values.name, icon: values.icon }

        if (
          theme &&
          // eslint-disable-next-line
          theme._id
        ) {
          await api.put(
            `/manager/starzen/audios/categories/${
              // eslint-disable-next-line
              theme._id
            }`,
            data
          )
          alertSuccess('Tema atualizado com sucesso!')
        } else {
          await api.post('/manager/starzen/audios/categories', data)
          alertSuccess('Tema criado com sucesso!')
        }
        getTemas()
        setThemeEdit('')
        getTemas()
      } catch (error) {
        alertWarning('Não foi possível salvar o tema')
      } finally {
        setLoading(false)
        setModal(false)
      }
    },
  })
  // eslint-disable-next-line
  useEffect(() => {
    if (theme) {
      formik.setValues({ name: theme.name, icon: theme.icon })
      setSelectedIcon(theme.icon)
    }
  }, [theme])

  const toggle = () => {
    formik.resetForm()
    setSelectedIcon('')
    setModal(false)
    setThemeEdit('')
  }

  const handleIconSelect = (iconUrl: string) => {
    setSelectedIcon(iconUrl)
    formik.setFieldValue('icon', iconUrl)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} maxWidth="700px">
      <ModalHeader toggle={toggle}>
        <div className="flex items-center justify-center flex-col text-center mt-4 ml-5">
          <h2 style={{ textTransform: 'uppercase' }} className="mb-n1">
            {theme ? 'EDITAR TEMA' : 'CRIAR TEMA'}
          </h2>
          <p className="font-weight-bold text-muted mb-0">
            Selecione um ícone e um nome para o tema
          </p>
        </div>
      </ModalHeader>

      <ModalBody style={{ paddingTop: '0' }}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          <Row className="align-items-center mt-4">
            <Col sm="6">
              <Label>Escolha um ícone:</Label>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(8, auto)',
                  gap: '15px',
                  width: '214%',
                  margin: '0 auto',
                  padding: '5px ',
                  borderRadius: '10px',
                  border: '1px solid #ced4da',
                }}
              >
                {iconUrls.map((iconUrl) => (
                  <button
                    key={iconUrl}
                    type="button"
                    style={{
                      width: '40px',
                      height: '40px',
                      padding: 0,
                      border:
                        selectedIcon === iconUrl ? '1px solid orange' : 'none',
                      backgroundColor:
                        selectedIcon === iconUrl ? 'white' : 'transparent',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleIconSelect(iconUrl)}
                    aria-label="Selecionar ícone"
                  >
                    <img
                      src={iconUrl}
                      alt="Ícone"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </button>
                ))}
              </div>
              {formik.errors.icon && formik.touched.icon && (
                <div className="input-feedback">{formik.errors.icon}</div>
              )}
            </Col>
          </Row>
          <Row className="align-items-center mt-4">
            <Label for="themeName" style={{ marginLeft: '15px' }}>
              Nome do Tema:
            </Label>
            <Input
              id="themeName"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              maxLength={50}
              placeholder="Informe o nome..."
              style={{ padding: '10px', maxWidth: '450px', margin: '0 auto' }}
              invalid={formik.touched.name && !!formik.errors.name}
            />

            {formik.errors.name && formik.touched.name && (
              <div className="input-feedback">{formik.errors.name}</div>
            )}
          </Row>
          <Row className="mt-4">
            <Col sm="6">
              <Button outline color="primary" onClick={toggle}>
                Cancelar
              </Button>
            </Col>
            <Col sm="6">
              <Button color="primary" type="submit">
                {theme ? 'Alterar' : 'Criar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalTemas
