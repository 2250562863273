/* eslint-disable react/jsx-no-bind */
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import React, { useEffect, useState } from 'react'
import api from 'configs/api'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledCollapse,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ModalFaq from './Components/ModalFaq'

const Faq = () => {
  const [datas, setDatas] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('Carregando Perguntas...')
  const [modal, setModal] = useState<boolean>(false)
  const [type, setType] = useState<string>('app')
  const [lang, setLang] = useState<string>('pt-BR')
  const [faq, setFaq] = useState<any>()
  const [modalDel, setModalDel] = useState<boolean>(false)
  const [faqId, setFaqId] = useState<string>('')

  const getFaq = async () => {
    setLoading(true)
    try {
      const res = await api.get(`backoffice/ms/v1/faqs?type=${type}`, {
        headers: {
          'Accept-Language': lang,
        },
      })
      setDatas(res.data)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getFaq()
  }, [type, lang])

  const action = async () => {
    setModal(false)
    await getFaq()
  }

  const toggle = () => {
    setModalDel(!modalDel)
    setFaqId('')
  }

  const deleteFaq = async () => {
    setLoading(true)
    try {
      await api.delete(`backoffice/ms/v1/faq/${faqId}`)
      toggle()
      getFaq()
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const modalDelete = () => (
    <Modal isOpen={modalDel} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3>Deseja excluir essa pergunta?</h3>
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-end">
          <Col sm="6">
            <Button outline color="secondary" onClick={toggle}>
              Cancelar
            </Button>
          </Col>
          <Col sm="6">
            <Button color="secondary" onClick={() => deleteFaq()}>
              Excluir
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  async function onDragEnd(result: any) {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }
    const item = datas[result.source.index]
    try {
      setLoading(true)
      setMessage('Atualizando ordem...')
      await api.put(`backoffice/ms/v1/faq/${item.id}/order`, {
        order: result.destination.index + 1,
      })
      await getFaq()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const List = ({ items }: any) =>
    items.map((item: any, index: number) => (
      <Draggable draggableId={item.id} key={item?.id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div>
              <Row className="m-2 align-items-center ">
                <Col sm="10" className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 128 512"
                  >
                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                  </svg>
                  <div>
                    <Button
                      color="gray"
                      className="text-justify"
                      id={`toggle${item.id}`}
                    >
                      {item.question}
                    </Button>
                  </div>
                </Col>

                <Col sm="2" className="d-flex justify-content-start">
                  <div>
                    <Button
                      onClick={() => {
                        setFaq(item)
                        setModal(true)
                      }}
                      color="default"
                      className="p-2 mr-2"
                    >
                      <i className="ni ni-ruler-pencil" />
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="default"
                      className="p-2 ml-2"
                      onClick={() => {
                        setFaqId(item?.id)
                        setModalDel(true)
                      }}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <UncontrolledCollapse toggler={`toggle${item.id}`}>
                <Card>
                  <CardBody>{item.answer}</CardBody>
                </Card>
              </UncontrolledCollapse>
              <hr className="m-2" color="#eee" />
            </div>
          </div>
        )}
      </Draggable>
    ))

  return (
    <div>
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="justify-content-start">
            <Button
              color="secondary  "
              className="float-right text-black"
              onClick={() => {
                setModal(true)
                setFaq({})
              }}
            >
              <i className="fas fa-plus" />
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow ">
          {loading ? (
            <Loading title={message || 'Carregando Perguntas...'} />
          ) : (
            <>
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center">
                  <Col sm="8" className="mb-2">
                    <h3 className="mb-0 text-black">FAQ</h3>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="mb-0">
                      <Input
                        type="select"
                        style={{ paddingRight: 10 }}
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                      >
                        <option value="pt-BR">Português</option>
                        <option value="en">Inglês</option>
                        <option value="es">Espanhol</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="mb-0">
                      <Input
                        type="select"
                        style={{ paddingRight: 10 }}
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <option value="app">Aplicativo</option>
                        <option value="app-internacional">App Global</option>
                        <option value="site">Web Site</option>
                        <option value="site-internacional">
                          Web Site Internacional
                        </option>
                        {/* <option value="doctor">Area Médica</option>
                        <option value="company">Portal Empresa</option> */}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>

              <Row>
                <div className="col ">
                  <Row className="col m-2">
                    <Col sm="1" />
                    <Col sm="9">Perguntas</Col>

                    <Col sm="2">Ações</Col>
                  </Row>
                  <Card className="shadow col  ">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="list">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <List items={datas} />
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Card>
                </div>
              </Row>
            </>
          )}
        </Card>
      </Container>
      <ModalFaq modal={modal} setModal={setModal} action={action} faq={faq} />
      {modalDelete()}
    </div>
  )
}

export default Faq
