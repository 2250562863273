import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'

import { Field, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import Loading from 'components/Loading'
import { cpfCnpjMask } from 'js-essentials-functions'
import { alertSuccess } from 'utils/toast'
import ComponentBack from 'components/ComponentBack'
import useSpecialty from 'hooks/useSpecialty'
import TableHealthPost from './Components/TableHealthPost'
import {
  createPrefecture,
  getDatasPrefecture,
  updatePrefecture,
} from './services'

const schemaRegister = yup.object().shape({
  legalName: yup.string().required('Informe a pergunta!'),
  legalNature: yup.string().required('Informe a resposta!'),
  cnpj: yup.string().required('Informe a resposta!'),
})

const Prefecture = () => {
  const { filterSpecialtiesActive, getSpecialties } = useSpecialty()

  const [specialtiesAll, setSpecialtiesAll] = useState<any>()
  const [healthPost, setHealthPost] = useState<any>()
  const [areaId, setAreaId] = useState<String[]>([])

  const [uuidPrefecture, setUuidPrefecture] = useState('')
  const [editable, setEditable] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [cpf, setCpf] = useState('')
  // const [page, setPage] = useState(1)
  // const [numberOfPages, setNumberOfPages] = useState(1)
  const { state }: any = useLocation()
  const history = useHistory()

  const initialValues = useMemo(
    () => ({
      legalName: '',
      legalNature: '',
      cnpj: '',
    }),
    []
  )
  const checkedSpecialty = () =>
    specialtiesAll
      .map((specialty: any) => {
        if (specialty.checked) {
          return String(specialty.id)
        }
        return null
      })
      .filter((area: any) => !!area)
      .join()

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const datas = {
        cnpj: values.cnpj,
        legalName: values.legalName,
        legalNature: values.legalNature,
        doctorsId: '',
        areasId: String(checkedSpecialty()),
      }

      if (!uuidPrefecture) {
        const { status } = await createPrefecture(datas)
        if (status) {
          alertSuccess('Clínica cadastrada com sucesso!')
          history.push('/admin/clinicas')
        }
      } else {
        const { status } = await updatePrefecture({
          uuid: uuidPrefecture,
          datas,
        })
        if (status) {
          alertSuccess('Clínica atualizada com sucesso!')
        }
        setEditable(false)
      }
      setLoading(false)
    },
  })

  const getAllSpecialties = useCallback(async () => {
    setSpecialtiesAll(
      filterSpecialtiesActive().map((specialty: any) => ({
        ...specialty,
        checked: areaId.includes(String(specialty.id)),
      }))
    )
  }, [areaId])

  const getPrefecture = async (uuid: string) => {
    setLoading(true)
    const { status, dataPrefecture, datasPost, areasId } =
      await getDatasPrefecture(uuid)

    if (status) {
      await getSpecialties()
      setHealthPost(datasPost)
      const arrayAreaId: any = []

      areasId.map((area: any) => {
        arrayAreaId.push(area.specialty_id)
      })

      setAreaId(arrayAreaId)
      formik.setValues({
        legalName: dataPrefecture?.legalName,
        legalNature: dataPrefecture?.legalNature,
        cnpj: dataPrefecture?.cnpj,
      })
      setLoading(false)
    } else {
      console.log('erro')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (state?.params?.id) {
      getPrefecture(state?.params?.id)
      setUuidPrefecture(state?.params?.id)
    } else {
      setEditable(true)
    }
  }, [])

  useEffect(() => {
    getAllSpecialties()
  }, [areaId])

  const onChangeCheckedSpecialty = (id: string) => {
    const newDatas = specialtiesAll.map((specialty: any) => {
      if (specialty.id === id) {
        if (specialty.checked) {
          return {
            ...specialty,
            checked: false,
          }
        }
        return {
          ...specialty,
          checked: true,
        }
      }
      return specialty
    })
    setSpecialtiesAll(newDatas)
  }

  // const handleIncludeDoctors = (doc: any) => {
  //   if (doctorsInclused.includes(doc)) {
  //     setDoctorsInclused(doctorsInclused)
  //   } else {
  //     setDoctorsInclused((doct) => (doct ? [...doct, doc] : [doc]))
  //     setDoctors((doct) => doct?.filter((d) => d !== doc))
  //   }
  // }

  // const removeInclused = (doc: any) => {
  //   setDoctorsInclused((doct) => doct?.filter((d) => d !== doc))
  //   setDoctors((doct) => (doct ? [...doct, doc] : [doc]))
  // }

  const formRegister = () => (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="align-items-center justify-content-between">
          <Col sm="9" className="mb-2">
            <h3 className="mb-0 text-black">
              {!uuidPrefecture ? 'Cadastrar Clínica' : state?.params.name}
            </h3>
          </Col>
          {!uuidPrefecture && (
            <Col lg="auto ">
              <Button color="primary" type="submit" disabled={!formik.isValid}>
                Cadastar
              </Button>
            </Col>
          )}
          {!!uuidPrefecture && !editable && (
            <Col lg="auto ">
              <Button
                color="secondary"
                onClick={() => {
                  setEditable(true)
                }}
              >
                Editar
              </Button>
            </Col>
          )}
          {!!uuidPrefecture && editable && (
            <Col lg="auto ">
              <Button color="primary" type="submit" disabled={!formik.isValid}>
                Salvar
              </Button>
            </Col>
          )}
        </Row>
        <div className="pl-lg-4 mt-5">
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Nome Jurídico
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-username"
                  placeholder="Informe o Nome Jurídico.."
                  name="legalName"
                  type="text"
                  disabled={!editable}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.legalName}
                  error={!!formik.errors.legalName}
                />
                {formik.errors.legalName && formik.touched.legalName && (
                  <div className="input-feedback">
                    {formik.errors.legalName}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Nome Fantasia
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-username"
                  placeholder="Informe o nome.."
                  name="legalNature"
                  type="text"
                  disabled={!editable}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.legalNature}
                  error={!!formik.errors.legalNature}
                />
                {formik.errors.legalNature && formik.touched.legalNature && (
                  <div className="input-feedback">
                    {formik.errors.legalNature}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  CNPJ
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-username"
                  placeholder="Informe o CNPJ.."
                  name="cnpj"
                  type="text"
                  maxLength={18}
                  disabled={!!uuidPrefecture}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={cpfCnpjMask(formik.values.cnpj || '')}
                  error={!!formik.errors.cnpj}
                />
                {formik.errors.cnpj && formik.touched.cnpj && (
                  <div className="input-feedback">{formik.errors.cnpj}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col lg="auto" className="mb-0">
              <h3 className="form-control-label">Especialidades Disponíveis</h3>
              <div className="overflow-auto d-flex mb-0">
                <FormGroup className="d-flex flex-wrap">
                  {specialtiesAll?.map((specialty: any) => (
                    <Label
                      className="mr-3 align-items-center justify-content-center"
                      size="sm"
                      key={specialty.id}
                    >
                      <Field
                        name="specialties"
                        type="checkbox"
                        disabled={!editable}
                        className="mr-2 align-items-center justify-content-center"
                        value={specialty.name}
                        onClick={() => {
                          onChangeCheckedSpecialty(specialty.id)
                        }}
                        checked={specialty.checked}
                      />
                      {specialty.name}
                    </Label>
                  ))}
                </FormGroup>
              </div>
            </Col>
          </Row>
          {/* {doctorsInclused && doctorsInclused?.length !== 0 && (
            <>
              <hr style={{ marginTop: 0 }} />
              <Row className="align-items-center ">
                <Col sm="auto" className="mb-2">
                  <h4 className="mb-0 text-black">Médicos Inclusos</h4>
                </Col>
              </Row>
              <Row>
                <div className="col">
                  <Table size="sm">
                    <thead>
                      <tr className="anima-to-horizontal">
                        <th scope="col" className="anima-to-horizontal">
                          #
                        </th>
                        <th scope="col" className="anima-to-horizontal">
                          name
                        </th>
                        <th scope="col" className="anima-to-horizontal">
                          email
                        </th>
                        <th scope="col" className="anima-to-horizontal">
                          documento
                        </th> 
                        {editable && (
                          <th scope="col" className="anima-to-horizontal">
                            Ação
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {doctorsInclused?.map((dat) => (
                        <tr key={dat.id}>
                          <th scope="row">{dat.id}</th>
                          <td>{dat.name}</td>
                          <td>{dat.email}</td>
                           <td>{cpfCnpjMask(dat.document)}</td> 
                          {editable && (
                            <td width="10%">
                              <Button
                                size="sm"
                                outline
                                type="button"
                                color="danger"
                                className="animated"
                                onClick={() => removeInclused(dat)}
                              >
                                Remover
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </>
          )}
          <hr style={{ marginTop: 0 }} />
          <Row className="d-flex align-items-center justify-content-between mt-2">
            <Col sm="auto" className="mb-2">
              <h4 className="mb-0 text-black">Médicos Disponiveis</h4>
            </Col>
            <Col sm="auto" className="d-flex justify-content-end">
              <Col sm="8">
                <Input
                  type="text"
                  id="filtrar-cpf"
                  bsSize="sm"
                  placeholder="Filtrar pelo CPF..."
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                />
              </Col>
              <Col sm="auto">
                <Button
                  sm="auto"
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    getDoctors(cpf)
                  }}
                >
                  Buscar
                </Button>
              </Col>
              <Col sm="auto">
                <Button
                  sm="auto"
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setCpf('')
                    getDoctors('')
                  }}
                >
                  Limpar
                </Button>
              </Col>
            </Col>
          </Row>
           <Row className="mt-2">
            <div className="col">
              <Table size="sm">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">name</th>
                    <th scope="col">email</th>
                     <th scope="col">documento</th>
                    {editable && <th scope="col">Ação</th>}
                  </tr>
                </thead>
                <tbody>
                  {doctors?.map((dat) => (
                    <tr key={dat.id}>
                      <th scope="row">{dat.id}</th>
                      <td>{dat.name}</td>
                      <td>{dat.email}</td>
                       <td>{cpfCnpjMask(dat.document)}</td> 
                      {editable && (
                        <td width="10%">
                          <Button
                            size="sm"
                            outline
                            type="button"
                            color="success"
                            className="animated"
                            onClick={() => handleIncludeDoctors(dat)}
                          >
                            Incluir
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {numberOfPages !== 1 && (
                <Row className="d-flex justify-content-end">
                  <PaginationComponent
                    numberOfPages={numberOfPages}
                    page={page}
                    setPage={setPage}
                  />
                </Row>
              )}
            </div>
          </Row> */}
        </div>
      </Form>
    </FormikProvider>
  )

  if (loading) return <Loading title="Aguarde..." />
  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent border-0">
            {formRegister()}
          </CardHeader>
          <CardBody className="mt-0">
            {!!uuidPrefecture && healthPost && (
              <TableHealthPost
                healthPost={healthPost}
                prefectureId={uuidPrefecture}
                prefectureName={state?.params?.name}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
export default Prefecture
