import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import {
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
} from 'reactstrap'
import Loading from 'components/Loading'

// Context
import { useAuth } from 'context/authentication/auth.context'

// Api
import api from 'configs/api'

// Utils
import { alertDanger, alertSuccess } from 'utils/toast'
import { setStorage } from 'utils/storage'

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha'

// Imagens
import LogoBackoffice from '../../assets/img/brand/starbem-backoffice.png'

const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/

export const schemaLogin = yup.object().shape({
  email: yup
    .string()
    .required('Informe o seu email!')
    .email('Informe um email válido!'),
  password: yup
    .string()
    .required('Informe sua senha!')
    .min(8, 'A senha deve conter no mínimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiúsculas e minúsculas, números e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
})

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [verify, setVerify] = useState(false)
  const [resend, setResend] = useState(false)
  const [code, setCode] = useState('')
  const [userId, setUserId] = useState('')
  const { signInSuccess, userLogged } = useAuth()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const recaptcha = useRef<any>(null)

  const staging =
    process.env.REACT_APP_API_URL === 'https://gateway.starbem.app'

  useEffect(() => {
    if (userLogged()) history.push('/admin/index')
  }, [])

  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: async () => {
      setLoading(true)
      staging && recaptcha.current.execute()
    },
  })

  const handleReCAPTCHA = async () => {
    try {
      const response = await api.post('backoffice/ms/v1/user/login', {
        email: formik.values.email,
        password: formik.values.password,
      })

      const { user } = response.data
      setUserId(user.id)
      setVerify(true)
      setLoading(false)
    } catch (err: any) {
      const error = err.response?.data?.error || 'Erro inesperado'
      setLoading(false)
      alertDanger(error)
    }
  }

  const handleResend = async () => {
    setResend(true)
    try {
      await api.post('backoffice/ms/v1/user/login/verify/resend', {
        user_id: userId,
      })

      alertSuccess(`Código enviado`)
    } catch (err: any) {
      const error = err.response?.data?.message || 'Erro ao enviar código'
      alertDanger(error)
    }

    const myTimeout = setTimeout(() => {
      setResend(false)
      clearTimeout(myTimeout)
    }, 50000)
  }

  const validateCode = async () => {
    setLoading(true)
    try {
      const response = await api.post('backoffice/ms/v1/user/tokens/verify', {
        user_id: userId,
        application: 'manager',
        code,
      })

      const { user, token } = response.data

      signInSuccess(token)
      setStorage('@ManagerStarbem:user', user)
      setLoading(false)
      const username = user.name.split(' ')
      alertSuccess(`Seja Bem Vindo ${username[0]} ${username[1]}`)
      history.push('/admin/index')
    } catch (err: any) {
      const error = err.response?.data?.message || 'Erro inesperado'
      setLoading(false)
      alertDanger(error)
    }
  }

  const ContentLogin = () => (
    <Row className="bg-white border-0 container-card-login">
      <CardBody className="px-lg-2 py-lg-2">
        <div className="mb-5">
          <img src={LogoBackoffice} alt="logo starbem" />
        </div>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={!!formik.errors.email}
                />
              </InputGroup>
              {formik.errors.email && formik.touched.email && (
                <div className="input-feedback">{formik.errors.email}</div>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={!!formik.errors.password}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    {showPassword ? (
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i className="far fa-eye" />
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i className="far fa-eye-slash" />
                      </button>
                    )}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {formik.errors.password && formik.touched.password && (
                <div className="input-feedback">{formik.errors.password}</div>
              )}
            </FormGroup>
            <div className="text-center">
              {staging ? (
                <>
                  <Button
                    block
                    className="my-4"
                    color="secondary"
                    type="submit"
                  >
                    Entrar
                  </Button>
                  <ReCAPTCHA
                    ref={recaptcha}
                    size="invisible"
                    sitekey={`${process.env.REACT_APP_RECAPTCHA}`}
                    onChange={handleReCAPTCHA}
                  />
                </>
              ) : (
                <Button
                  block
                  className="my-4"
                  color="secondary"
                  type="submit"
                  onClick={handleReCAPTCHA}
                >
                  Entrar
                </Button>
              )}
            </div>
          </Form>
        </FormikProvider>
      </CardBody>
    </Row>
  )

  const ContentVerify = () => (
    <Row className="bg-white border-0 container-card-login">
      <CardBody className="px-lg-2 py-lg-2 text-center">
        <div className="mb-5">
          <img src={LogoBackoffice} alt="logo starbem" />
        </div>
        <div className="mb-3">
          <span
            style={{
              fontSize: 20,
              color: '#666666',
              fontWeight: 700,
            }}
          >
            Autenticação de duas etapas
          </span>
        </div>
        <div className="mb-3">
          <span
            style={{
              fontSize: 14,
              color: '#666666',
              fontWeight: 400,
            }}
          >
            Informe o código enviado em seu email.
          </span>
        </div>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Digite o código"
              type="text"
              name="code"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
          </InputGroup>
        </FormGroup>
        <Button
          className="mt-4"
          style={{ color: ' #ff3f72' }}
          onClick={() => handleResend()}
          outline
          size="sm"
          color="link"
          disabled={resend}
        >
          Reenviar código
        </Button>
        <div className="text-center">
          <Button
            className="mt-4"
            block
            color="secondary"
            onClick={() => validateCode()}
          >
            Enviar
          </Button>
        </div>
      </CardBody>
    </Row>
  )

  return (
    <>
      {loading && <Loading title="Carregando..." />}
      {verify ? ContentVerify() : ContentLogin()}
    </>
  )
}

export default Login
