import React, { useState } from 'react'
import classnames from 'classnames'

import {
  Card,
  Container,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { Companies, Rh } from './Tabs'

const AllCompanies = () => {
  const [tabs, setTabs] = useState(1)

  return (
    <div>
      <HeaderNeutro />
      <Container fluid>
        <div className="nav-wrapper">
          <Nav
            className="flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 1,
                })}
                onClick={() => setTabs(1)}
                href="#Configurações"
                role="tab"
              >
                <i className="ni ni-building mr-2" />
                Empresas Cadastradas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 2,
                })}
                onClick={() => setTabs(2)}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-users mr-2" />
                Rh Empresas
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody className="p-0">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">{tabs === 1 && <Companies />}</TabPane>
              <TabPane tabId="tabs2">{tabs === 2 && <Rh />}</TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AllCompanies
