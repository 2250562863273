import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'

import Loading from 'components/Loading'

import ModalStarzen from '../Components/ModalStarzen'

const Audios = () => {
  const [modalDel, setModalDel] = useState<any>('')

  const [modalStatus, setModalStatus] = useState<any>({
    id: '',
    status: '',
  })

  const [modal, setModal] = useState<boolean>(false)
  const [editAudio, setEditAudio] = useState<any>(null)
  const [audios, setAudios] = useState([])
  const [loading, setLoading] = useState(false)

  const toggle = () => {
    setModalDel('')
    setModalStatus({ id: '', status: '' })
  }

  const getAudios = async () => {
    setLoading(true)
    try {
      const res = await api.get('/manager/starzen?status=all')
      setAudios(res.data)
    } catch (err) {
      console.log(err)
      alertDanger('Erro ao carregar os temas.')
    }
    setLoading(false)
  }

  const handleStatusChange = async () => {
    setLoading(true)
    const newStatus = modalStatus.status === 'active' ? 'inactive' : 'active'
    try {
      await api.patch(`/manager/starzen/${modalStatus.id}`, {
        status: newStatus,
      })
      alertSuccess(
        `Áudio ${
          modalStatus.status === 'active' ? 'ativado' : 'desativado'
        } com sucesso`
      )
      getAudios()
      toggle()
    } catch (error) {
      console.error(error)
      alertDanger('Não foi possível alterar o status do áudio')
    }
    setLoading(false)
  }

  const deleteAudio = async () => {
    setLoading(true)
    try {
      await api.patch(`/manager/starzen/${modalDel}`, {
        status: 'deleted',
      })
      toggle()
      getAudios()
      alertSuccess('Áudio excluído com sucesso')
    } catch (error) {
      console.error(error)
      alertDanger('Não foi possível excluir o áudio')
    }
    setLoading(false)
  }

  const modalDelete = () => (
    <Modal isOpen={modalDel} toggle={toggle}>
      <ModalHeader>
        <h2>Você deseja realmente remover esse Áudio?</h2>
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-end">
          <Col sm="6">
            <Button color="primary" onClick={deleteAudio}>
              Sim
            </Button>
          </Col>
          <Col sm="6">
            <Button color="secondary" onClick={toggle}>
              Não
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  useEffect(() => {
    getAudios()
  }, [])

  return (
    <div>
      <ModalStarzen
        modal={modal}
        setModal={setModal}
        getAudios={getAudios}
        setLoading={setLoading}
        editAudio={editAudio}
        setEditAudio={setEditAudio}
      />
      <Card className="shadow">
        {loading && <Loading title="Carregando..." />}
        <CardHeader className="bg-transparent border-0">
          <Row className="align-items-center">
            <Col sm="9" className="mb-2">
              <h3 className="mb-0 text-black">Áudios</h3>
            </Col>
            <Col sm="auto" className="ml-auto">
              <Button
                sm="auto"
                color="primary"
                onClick={() => {
                  setModal(true)
                }}
                className="mt-2 float-right"
              >
                <i className="fas fa-plus" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table
                className="align-items-center table-flush"
                responsive
                hover
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Tema</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Tempo</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right pr-50">
                      <span className="mr-5">Ação</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {audios?.map((audio: any) => (
                    <tr
                      key={
                        // eslint-disable-next-line
                        audio._id
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        {audio.category
                          ? audio.category.name
                          : 'Categoria não definida'}
                      </td>
                      <td>{audio.name}</td>
                      <td>{audio.time}</td>
                      <td
                        className={
                          audio.status === 'active'
                            ? 'text-success'
                            : 'text-danger'
                        }
                      >
                        {audio.status === 'active' ? 'Ativo' : 'Inativo'}
                      </td>
                      <td className="d-flex align-items-center justify-content-end">
                        <div style={{ width: 30 }}>
                          <Button
                            size="sm"
                            color="white"
                            className="border #D0D5DD"
                            onClick={() => {
                              setEditAudio(audio)
                              setModal(true)
                            }}
                          >
                            <i className="fa fa-pen" />
                          </Button>
                        </div>
                        <div style={{ width: 30, marginLeft: 10 }}>
                          <Button
                            size="sm"
                            color="white"
                            className="border border #D0D5DD"
                            onClick={() =>
                              setModalStatus({
                                // eslint-disable-next-line no-underscore-dangle
                                id: audio._id,
                                status: audio.status,
                              })
                            }
                          >
                            {audio.status === 'active' ? (
                              <i className="fa fa-ban" />
                            ) : (
                              <i className="fas fa-check" />
                            )}
                          </Button>
                        </div>
                        <div style={{ width: 30, marginLeft: 10 }}>
                          <Button
                            size="sm"
                            color="white"
                            className="border border-danger"
                            onClick={() =>
                              setModalDel(
                                // eslint-disable-next-line no-underscore-dangle
                                audio._id
                              )
                            }
                          >
                            <i className="far fa-trash-alt text-danger" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Modal isOpen={modalStatus.id}>
          <ModalHeader>
            <h2>
              {modalStatus.status === 'active'
                ? 'Desativar Áudio'
                : 'Ativar Áudio'}
            </h2>
          </ModalHeader>
          <ModalBody>
            <Row className="d-flex justify-content-end">
              <Col sm="6">
                <Button color="primary" onClick={handleStatusChange}>
                  Sim
                </Button>
              </Col>
              <Col sm="6">
                <Button color="secondary" onClick={toggle}>
                  Não
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        {modalDelete()}
      </Card>
    </div>
  )
}

export default Audios
