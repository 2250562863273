/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import './style.css'
import * as yup from 'yup'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Media,
  Row,
  Col,
  Label,
  Input,
  Button,
  Form,
} from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import RichTextEditor from 'react-rte'
import { useFormik, FormikProvider } from 'formik'
import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'

const schemaRegister = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  author: yup.string().required('Campo obrigatório'),
  category: yup.string().required('Campo obrigatório'),
  time: yup.string().required('Campo obrigatório'),
})

const ModalContent: React.FC<any> = ({
  modal,
  setModal,
  setLoading,
  action,
  getContent,
  setGetContent,
}) => {
  const [capa, setCapa] = useState<any>()
  const [fileImage, setFileImage] = useState<any>()
  const [value, setValue] = useState(RichTextEditor.createEmptyValue())

  const initialValues = useMemo(
    () => ({
      title: '',
      author: '',
      category: '',
      time: '',
    }),
    []
  )

  const onDrop = useCallback((acceptedFiles) => {
    setCapa(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
  })

  const onDropImage = useCallback((acceptedFiles) => {
    setFileImage(acceptedFiles)
  }, [])

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      accept: ['image/jpeg', 'image/png'],
      onDrop: onDropImage,
    })

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const data = new FormData()

      if (getContent?.id) {
        data.append('title', values.title)
        data.append('author', values.author)
        data.append('text', value.toString('html'))
        data.append('category', values.category)
        data.append('time', values.time)

        if (capa) {
          capa.map((file: any) => data.append('image', file))
        }
        if (fileImage) {
          fileImage.map((file: any) => data.append('photo_author', file))
        }

        try {
          await api.put(`/backoffice/ms/v1/content/${getContent?.id}`, data)
          alertSuccess('Conteúdo alterado com sucesso!')
          setCapa(null)
          setFileImage(null)
          setModal(false)
          formik.resetForm()
          setValue(RichTextEditor.createEmptyValue())
          action()
        } catch (error) {
          alertWarning('Erro ao editar o conteúdo')
        }
      } else {
        if (capa) {
          capa.map((file: any) => data.append('image', file))
        }
        if (fileImage) {
          fileImage.map((file: any) => data.append('photo_author', file))
        }
        data.append('title', values.title)
        data.append('author', values.author)
        data.append('text', value.toString('html'))
        data.append('category', values.category)
        data.append('time', values.time)

        if (!capa) {
          setLoading(false)
          alertWarning('A imagem da capa é obrigatória.')
        }

        try {
          await api.post('/backoffice/ms/v1/content', data)

          alertSuccess('Conteúdo criado com sucesso!')
          setCapa(null)
          setFileImage(null)
          setModal(false)
          formik.resetForm()
          setValue(RichTextEditor.createEmptyValue())
          action()
        } catch (err: any) {
          alertWarning(
            err?.response?.data?.message || 'Não foi possível criar esse evento'
          )
        }
      }

      setLoading(false)
    },
  })

  const toggle = () => {
    setModal(!modal)
    setFileImage(null)
    setCapa(null)
    formik.resetForm()
    setGetContent(null)
    setValue(RichTextEditor.createEmptyValue())
  }

  useEffect(() => {
    if (getContent?.id) {
      formik.setValues({
        title: getContent.title,
        author: getContent.author,
        category: getContent.category,
        time: getContent.time,
      })
      setValue(RichTextEditor.createValueFromString(getContent.text, 'html'))
    }
  }, [getContent])

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="justify-content-center align-items-center">
          <div className="mt-3">
            <h2 className="text-uppercase text-center">
              {getContent?.id ? 'Editar conteúdo' : 'Adicionar conteúdo'}
            </h2>
            <h5 className="text-center mt-3">Capa</h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <input {...getInputProps()} />
              <Media
                {...getRootProps()}
                style={{
                  width: '450px',
                  height: '200px',
                  borderRadius: '8px',
                  border: '3px dashed #dee2e6',
                  cursor: 'pointer',
                }}
                className=" align-items-center justify-content-center position-relative"
              >
                {capa?.length || getContent?.image ? (
                  <img
                    src={
                      getContent?.image && !capa?.[0]
                        ? getContent?.image
                        : URL.createObjectURL(capa[0])
                    }
                    alt="Logo"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <h4 className=" text-gray">
                    Arraste e solte o arquivo aqui, ou clique para selecioná-lo
                  </h4>
                )}
              </Media>
              <Row>
                <Col
                  sm="8"
                  className=" align-items-center justify-content-between mt-4"
                >
                  <Label>Selecione o tema</Label>
                  <Input
                    type="select"
                    name="category"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    error={formik.errors.category}
                  >
                    <option>Selecione</option>
                    <option>Saúde mental</option>
                    <option>Bem-Estar</option>
                  </Input>
                  {formik.errors.category && formik.touched.category && (
                    <div className="input-feedback">
                      {formik.errors.category}
                    </div>
                  )}
                </Col>
                <Col
                  sm="4"
                  className=" align-items-center justify-content-between mt-4"
                >
                  <Label>Tempo de leitura</Label>
                  <Input
                    placeholder="00:00"
                    name="time"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/\D/g, '')
                      inputValue = inputValue.slice(0, 4)
                      const match = inputValue.match(/^(\d{0,2})(\d{0,2})$/)
                      if (match) {
                        inputValue = match.slice(1, 3).join(':')
                      }
                      formik.setFieldValue('time', inputValue)
                    }}
                    value={formik.values.time}
                    error={formik.errors.time}
                  />
                  {formik.errors.time && formik.touched.time && (
                    <div className="input-feedback">{formik.errors.time}</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  sm="8"
                  className=" align-items-center justify-content-between mt-4"
                >
                  <Label>Nome do autor(a)</Label>
                  <Input
                    placeholder="Informe o nome..."
                    type="text"
                    name="author"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.author}
                    error={formik.errors.author}
                  />
                  {formik.errors.author && formik.touched.author && (
                    <div className="input-feedback">{formik.errors.author}</div>
                  )}
                </Col>
                <Col
                  sm="4"
                  className=" align-items-center justify-content-between mt-4"
                >
                  <input {...getInputPropsImage()} />
                  <Media
                    {...getRootPropsImage()}
                    style={{
                      width: '130px',
                      height: '80px',
                      borderRadius: '8px',
                      border: '3px dashed #dee2e6',
                      cursor: 'pointer',
                    }}
                    className=" align-items-center justify-content-center position-relative"
                  >
                    {fileImage?.length || getContent?.photo_author ? (
                      <img
                        src={
                          getContent?.photo_author && !fileImage?.[0]
                            ? getContent.photo_author
                            : URL.createObjectURL(fileImage[0])
                        }
                        alt="Logo"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <h5 className=" text-gray">Foto autor(a)</h5>
                    )}
                  </Media>
                </Col>
              </Row>
              <Row>
                <Col
                  sm="12"
                  className=" align-items-center justify-content-between mt-4"
                >
                  <Label>Título</Label>
                  <Input
                    placeholder="Informe o título..."
                    type="text"
                    name="title"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={formik.errors.title}
                  />
                  {formik.errors.title && formik.touched.title && (
                    <div className="input-feedback">{formik.errors.title}</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className=" align-items-center justify-content-between mt-4">
                  <Label>Texto</Label>
                  <div className="editor-container">
                    <RichTextEditor
                      rootStyle={{ width: '100%', border: 'none' }}
                      value={value}
                      onChange={setValue}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm="6">
                  <Button
                    outline
                    color="primary"
                    onClick={() => {
                      toggle()
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6">
                  <Button color="secondary" type="submit">
                    {getContent?.id ? 'Atualizar' : 'Adicionar'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalContent
