import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap'
import api from 'configs/api'
import * as yup from 'yup'
import { alertSuccess, alertWarning } from 'utils/toast'
import ComponentBack from 'components/ComponentBack'
import { cpfCnpjMask } from 'js-essentials-functions'

const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  cnes: yup.string().required('Informe o CNES!'),
  attendence: yup.string().required('Selecione o tipo de atendimento!'),
})

const attendences = [
  {
    label: 'Via Whatsapp',
    key: 'whatsapp',
  },
  {
    label: 'Via Plataforma',
    key: 'aplication',
  },
]

const HealthPost = () => {
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)
  const [users, setUsers] = useState([])

  const { state }: any = useLocation()
  const newPost = !state.name
  const history = useHistory()
  const initialValues = useMemo(
    () => ({
      name: '',
      cnes: '',
      attendence: '',
      return: '0',
      timeReturn: '0',
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)

      const timeReturn = () => {
        if (Number(values.return) === 0) return 0
        if (Number(values.timeReturn) === 0) return 15
        return Number(values.timeReturn)
      }

      if (newPost) {
        try {
          await api.post('manager/health-post', {
            prefecture_uuid: state?.prefectureId,
            name: values.name,
            cnes: values.cnes,
            attendence: values.attendence ? values.attendence : null,
            return: Number(values.return),
            time_return: timeReturn(),
          })
          alertSuccess('Unidade cadastrado com sucesso!')
          history.goBack()
          setLoading(false)
        } catch (err: any) {
          alertWarning(err.response.data.message)
          setLoading(false)
        }
      } else {
        setEditable(false)

        try {
          await api.put(`manager/health-post/${state?.uuidPost}`, {
            name: values.name,
            cnes: values.cnes,
            prefecture_uuid: state?.prefectureId,
            attendence: values.attendence,
            return: Number(values.return),
            time_return: timeReturn(),
          })
          alertSuccess('Unidade atualizado com sucesso!')
          setLoading(false)
        } catch (err: any) {
          alertWarning(err.response.data.message)
          setLoading(false)
        }
      }
    },
  })

  const getHealthPost = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/manager/health-post/${state?.uuidPost}`)
      const { items } = response.data

      formik.setValues({
        name: items.healthPost?.name,
        cnes: items.healthPost?.cnes,
        attendence: items.healthPost?.attendence,
        return: String(items.healthPost?.return),
        timeReturn: String(items.healthPost?.time_return),
      })
      setUsers(items?.users)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (!newPost) {
      getHealthPost()
    }
  }, [])

  if (loading) return <Loading title="Carregando..." />
  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <CardHeader className="bg-transparent border-0">
                <Row className="align-items-center justify-content-between">
                  <Col sm="9">
                    <h3 className="mb-0 text-black">
                      {newPost ? 'Cadastrar Unidade' : 'Unidade'}
                    </h3>
                  </Col>
                  {!newPost && !editable && (
                    <Col sm="auto">
                      <Button
                        sm="auto"
                        color="secondary"
                        onClick={() => {
                          setEditable(true)
                        }}
                      >
                        Editar
                      </Button>
                    </Col>
                  )}
                  {!newPost && editable && (
                    <Col sm="auto">
                      <Button sm="auto" color="secondary" type="submit">
                        Salvar
                      </Button>
                    </Col>
                  )}
                  {newPost && (
                    <Col sm="auto">
                      <Button sm="auto" color="secondary" type="submit">
                        Cadastrar
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody className="pb-0">
                <Row className="align-items-center">
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nome
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Informe o Nome "
                        name="name"
                        type="text"
                        disabled={!editable && !newPost}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={!!formik.errors.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div className="input-feedback">
                          {formik.errors.name}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Cnes
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Informe o número do Cnes"
                        name="cnes"
                        type="text"
                        disabled={!editable && !newPost}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.cnes}
                        error={!!formik.errors.cnes}
                      />
                      {formik.errors.cnes && formik.touched.cnes && (
                        <div className="input-feedback">
                          {formik.errors.cnes}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label htmlFor="attendence">Tipo de atendimento</label>

                      <Input
                        type="select"
                        id="attendence"
                        name="attendence"
                        value={formik.values.attendence}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!editable && !newPost}
                      >
                        <option value="" disabled>
                          Selecione...
                        </option>
                        {attendences.map((e) => (
                          <option key={e.key} value={e.key} label={e.label} />
                        ))}
                      </Input>
                      {formik.errors.attendence &&
                        formik.touched.attendence && (
                          <div className="input-feedback">
                            {formik.errors.attendence}
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Retorno
                      </label>
                      <Input
                        type="select"
                        id="return"
                        name="return"
                        value={formik.values.return}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!editable && !newPost}
                      >
                        <option value="" disabled>
                          Selecione...
                        </option>
                        <option key={0} value={0} label="Não" />
                        <option key={1} value={1} label="Sim" />
                      </Input>
                    </FormGroup>
                  </Col>
                  {formik.values.return === '1' && (
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-timeReturn"
                        >
                          Tempo de expiração
                        </label>
                        <Input
                          type="select"
                          id="timeReturn"
                          name="timeReturn"
                          value={formik.values.timeReturn}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!editable && !newPost}
                        >
                          <option
                            selected
                            key={15}
                            value={15}
                            label="15 dias"
                          />
                          <option key={30} value={30} label="30 dias" />
                        </Input>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Form>
          </FormikProvider>
          <hr style={{ marginBottom: 1 }} />
          {!newPost && (
            <CardBody>
              <Row className="align-items-center justify-content-between mb-2">
                <Col sm="9" className="mb-2">
                  <h3 className="text-black">Usuários</h3>
                </Col>
                <Col sm="3" className="mb-2">
                  <Button
                    sm="auto"
                    color="secondary"
                    onClick={() => {
                      history.push(`/admin/usuario/unidade/novo`, {
                        params: state,
                      })
                    }}
                  >
                    Cadastrar Usuário
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                      hover
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">id</th>
                          <th scope="col">nome</th>
                          <th scope="col">email</th>
                          <th scope="col">cpf</th>
                          <th scope="col">status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.map((d: any) => (
                            <tr
                              key={d.id}
                              onClick={() => {
                                history.push(
                                  `/admin/usuario/unidade/${d.name}`,
                                  {
                                    params: state,
                                    user: d,
                                  }
                                )
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <td>{d.id}</td>
                              <td>{d.name}</td>
                              <td>{d.email}</td>
                              <td>{cpfCnpjMask(d.document ?? '')}</td>
                              <td>
                                {d.status === 'active' ? 'Ativo' : 'Inativo'}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            </CardBody>
          )}
        </Card>
      </Container>
    </div>
  )
}

export default HealthPost
