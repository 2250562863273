export const converteDate = (DataDDMMYY: string) => {
  const dataSplit = DataDDMMYY.split('/')
  const novaData = new Date(
    parseInt(dataSplit[2], 10),
    parseInt(dataSplit[1], 10) - 1,
    parseInt(dataSplit[0], 10)
  )
  return novaData
}

export function dateMaskUS(date: string) {
  const newValue = date.split(/\D/g).reverse()

  return `${newValue[0]}-${newValue[1]}-${newValue[2]}`
}
