import React, { useEffect, useState } from 'react'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import api from 'configs/api'
import Loading from 'components/Loading'
import { cpfCnpjMask } from 'js-essentials-functions'

interface IPrefectures {
  id: string
  uuid: string
  name: string
  cnpj: string
  status: number
}

const Prefectures = () => {
  const [loading, setLoading] = useState(false)
  const [prefeituras, setPrefeituras] = useState<IPrefectures[]>()
  const histoy = useHistory()

  const onBuscar = (e: any) => {
    const name = e.name.replaceAll(' ', '-').toLowerCase()

    histoy.push(`/admin/clinica/${name}`, {
      params: e,
    })
  }
  useEffect(() => {
    const getPref = async () => {
      setLoading(true)
      try {
        const response = await api.get('/manager/prefectures')
        const datas = response?.data?.items.map((item: any) => ({
          id: item.id,
          uuid: item.uuid,
          name: item.legal_name,
          cnpj: item.national_registration,
          status: item.status === 'active' ? 1 : 0,
        }))
        setPrefeituras(datas)
        setLoading(false)
      } catch (err: any) {
        setLoading(false)
      }
    }
    getPref()
  }, [])
  if (loading) return <Loading title="Carregando Clínicas..." />
  return (
    <div>
      <HeaderNeutro />
      <Container fluid>
        <Row className="mt-3 align-items-end d-flex ">
          <Col sm="auto">
            <Link to="/admin/clinica/novo">
              <Button sm="auto" color="secondary">
                <i className="fas fa-plus" />
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent border-0">
            <Row className="align-items-center">
              <Col sm="9" className="mb-2">
                <h3 className="mb-0 text-black">Clínicas Cadastradas</h3>
              </Col>
            </Row>
          </CardHeader>
          <Row className="mt-0">
            <div className="col">
              <Card className="shadow">
                <Table
                  className="align-items-center table-flush"
                  responsive
                  hover
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">nome</th>
                      <th scope="col">cnpj</th>
                      <th scope="col">status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {prefeituras?.map((prefeitura: IPrefectures) => (
                      <tr
                        key={prefeitura.id}
                        onClick={() => onBuscar(prefeitura)}
                        style={{ cursor: 'pointer' }}
                      >
                        <th scope="row">{prefeitura.id}</th>
                        <td>{prefeitura.name}</td>
                        <td>{cpfCnpjMask(prefeitura.cnpj)}</td>
                        <td>{prefeitura.status === 1 ? 'Ativo' : 'Inativo'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Card>
      </Container>
    </div>
  )
}
export default Prefectures
