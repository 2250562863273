/* eslint-disable consistent-return */
import React, { useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Row,
  CardText,
  CardBody,
  CardTitle,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import { cpfCnpjMask } from 'js-essentials-functions'
import { useLocation } from 'react-router-dom'
import { useExcelDownloder } from 'react-xls'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import { format } from 'date-fns'

import api from 'configs/api'
import Loading from 'components/Loading'
import { alertSuccess, alertDanger } from 'utils/toast'
import { reports, monthlyReport } from 'utils/reportsData'

const ReportsCompany = ({ company }: any) => {
  const { ExcelDownloder, Type } = useExcelDownloder()
  const params: any = useLocation()
  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState({
    start: '',
    end: '',
  })
  const [datesFinancial, setDatesFinancial] = useState({
    start: '',
  })

  const [download, setDownload] = useState<any>({
    id: 0,
    data: null,
  })

  const getReport = async (item: any) => {
    const { start, end } = dates
    if (!start && !end) {
      return alertDanger('Para acessar, preencha os períodos!')
    }

    const newStart = moment(start).format('DD/MM/YYYY')
    const newEnd = moment(end).format('DD/MM/YYYY')

    setLoading(true)
    try {
      const { data } = await api.get(
        item?.id > 4
          ? `${item.url}start=${start}&end=${end}`
          : `${item.url}start=${newStart}&end=${newEnd}`
      )

      if (data?.total > 0) {
        const mappedData = data.specialties.map((entry: any) => ({
          Especialidade: entry.specialty_name ? entry.specialty_name : '',
          'Quantidade de consultas realizadas': entry.total ? entry.total : '',
        }))
        setDownload({
          id: item.id,
          data: {
            Consultas: mappedData,
          },
        })

        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else if (data?.length > 0) {
        const mappedData = data.map((entry: any) => ({
          Nome: entry.name ? entry.name : '',
          Email: entry.email ? entry.email : '',
          Celular: entry.cellphone ? entry.cellphone : '',
          Tipo: entry.type ? entry.type : '',
          Documento: entry.document ? entry.document : '',
          Status:
            (entry.status === 'deleted' && 'excluído') ||
            (entry.status === 'active' && 'ativo') ||
            (entry.status === 'inactive' && 'inativo'),
          'Data de nascimento': entry.birthdate
            ? moment(entry.birthdate).format('DD/MM/YYYY')
            : '',
          'Data de cadastro': entry.created_at
            ? moment(entry.created_at).format('DD/MM/YYYY')
            : '',
          'Data de exclusão': entry.deleted_at
            ? moment(entry.deleted_at).format('DD/MM/YYYY')
            : '',
        }))

        setDownload({
          id: item.id,
          data: {
            Dados: mappedData,
          },
        })
        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else {
        alertDanger('Não foi encontrado nenhum dado')
      }
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  // eslint-disable-next-line consistent-return
  const getMonthlyReport = async (item: any) => {
    const { start } = datesFinancial
    if (!start) {
      return alertDanger('Por favor, selecione uma data.')
    }
    setLoading(true)
    try {
      const { data } = await api.get(`${item.url}`)
      if (data?.length === 0) {
        alertDanger('Não foi encontrado nenhum dado')
      }
      const headersExcluded = [
        'Beneficiário',
        'Categoria',
        'Data de cadastro',
        'Data da exclusão',
        'Valor por vida',
      ]
      const headers = [
        'Beneficiário',
        'Categoria',
        'Data de cadastro',
        'Valor por vida',
      ]

      const xlsxDataActive = []
      const xlsxDataExcluded = []
      const xlsxData = []

      company.national_registration
        ? xlsxDataActive.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_name
            }`}`,
          ])
        : xlsxDataActive.push([`Empresa: ${company?.legal_name}`])
      xlsxDataActive.push([``])

      // Beneficiários ativos
      xlsxDataActive.push(['Beneficiários cadastrados e ativos'])
      xlsxDataActive.push([''])
      xlsxDataActive.push(headers)

      // eslint-disable-next-line no-shadow
      data.users_actives.forEach((item: any) => {
        xlsxDataActive.push([
          item.customer_name,
          item.type,
          `${format(new Date(item?.created_at || ''), 'dd/MM/yyyy')}`,
          `R$ ${
            item.type === 'Titular' ? data.amount_holder : data.amount_dependent
          }`,
        ])
        if (item.dependents && item.dependents.length > 0) {
          item.dependents.forEach((dependent: any) => {
            xlsxDataActive.push([
              dependent.customer_name,
              dependent.type,
              `${format(new Date(dependent?.created_at || ''), 'dd/MM/yyyy')}`,
              `R$ ${data.amount_dependent}`,
            ])
          })
        }
      })

      xlsxDataActive.push([''])
      xlsxDataActive.push([`Total de titulares ativos: ${data?.active_holder}`])
      xlsxDataActive.push([
        `Total de dependentes ativos: ${data?.active_dependents}`,
      ])
      xlsxDataActive.push([`Total ativos: ${data?.total_lives_active}`])

      // Beneficiários excluídos (dentro do período de exclusão)

      company.national_registration
        ? xlsxDataExcluded.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_name
            }`}`,
          ])
        : xlsxDataExcluded.push([`Empresa: ${company?.legal_name}`])
      xlsxDataExcluded.push([``])

      xlsxDataExcluded.push([
        'Beneficiários excluídos (dentro do período de exclusão)',
      ])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push(headersExcluded)
      // eslint-disable-next-line no-shadow
      data.users_deleted_period.forEach((item: any) => {
        xlsxDataExcluded.push([
          item.customer_name,
          item.type,
          `${format(new Date(item?.created_at || ''), 'dd/MM/yyyy')}`,
          `${format(new Date(item?.deleted_at || ''), 'dd/MM/yyyy')}`,
          `R$ 0,00`,
        ])
        if (item.dependents && item.dependents.length > 0) {
          item.dependents.forEach((dependent: any) => {
            xlsxDataExcluded.push([
              dependent.customer_name,
              dependent.type,
              `${format(new Date(dependent?.created_at || ''), 'dd/MM/yyyy')}`,
              `${format(new Date(dependent?.deleted_at || ''), 'dd/MM/yyyy')}`,
              `R$ 0,00`,
            ])
          })
        }
      })
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        `Total de titulares excluídos: ${data.deleted_holder_period}`,
      ])
      xlsxDataExcluded.push([
        `Total de dependentes excluídos: ${data.deleted_dependent_period}`,
      ])
      xlsxDataExcluded.push([`Valor total: ${data.total_deleted_period}`])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        'Os usuários excluídos dentro do período de exclusão, do dia 1º ao dia 5 de cada mês não deverão ser efetuadas cobranças',
      ])
      xlsxDataExcluded.push([``])

      // Beneficiários excluídos (fora do período de exclusão)

      xlsxDataExcluded.push([
        'Beneficiários excluídos (fora do período de exclusão)',
      ])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push(headersExcluded)
      // eslint-disable-next-line no-shadow
      data.users_deleted.forEach((item: any) => {
        xlsxDataExcluded.push([
          item.customer_name,
          item.type,
          `${format(new Date(item?.created_at || ''), 'dd/MM/yyyy')}`,
          `${format(new Date(item?.deleted_at || ''), 'dd/MM/yyyy')}`,
          `R$ ${
            item.type === 'Titular' ? data.amount_holder : data.amount_dependent
          }`,
        ])
        if (item.dependents && item.dependents.length > 0) {
          item.dependents.forEach((dependent: any) => {
            xlsxDataExcluded.push([
              dependent.customer_name,
              dependent.type,
              `${format(new Date(dependent?.created_at || ''), 'dd/MM/yyyy')}`,
              `${format(new Date(dependent?.deleted_at || ''), 'dd/MM/yyyy')}`,
              `R$ ${data.amount_dependent}`,
            ])
          })
        }
      })
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        `Total de titulares excluídos: ${data.deleted_holder}`,
      ])
      xlsxDataExcluded.push([
        `Total de dependentes excluídos: ${data.deleted_dependents}`,
      ])
      xlsxDataExcluded.push([`Valor total: ${data.total_lives_deleted}`])

      // Extrato Geral

      company.national_registration
        ? xlsxData.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_name
            }`}`,
          ])
        : xlsxData.push([`Empresa: ${company?.legal_name}`])
      xlsxData.push([``])
      xlsxData.push(['Extrato Geral'])
      xlsxData.push([''])
      xlsxData.push([`Total de titulares ativos: ${data.active_holder}`])
      xlsxData.push([`Total de dependetes ativos: ${data.active_dependents}`])
      xlsxData.push([`Total de vidas ativas: ${data.total_lives_active}`])
      xlsxData.push([
        `Total mínimo de vidas em contrato: ${data.minimum_people}`,
      ])
      xlsxData.push([
        `Titulares excluídos fora do período de carência: ${data.deleted_holder}`,
      ])
      xlsxData.push([
        `Dependentes excluídos fora do período de carência: ${data.deleted_dependents}`,
      ])
      xlsxData.push([
        `Total de excluídos fora do período de carência: ${data.total_lives_deleted}`,
      ])
      xlsxData.push([`Total de Vidas: ${data.total_lives}`])
      xlsxData.push([`Valor Total de titulares: ${data.total_amount_holders}`])
      xlsxData.push([
        `Valor Total de dependentes: ${data.total_amount_dependents}`,
      ])
      xlsxData.push([
        `Valor Total de excluídos fora do período de carência: ${data.total_amount_deleted}`,
      ])
      xlsxData.push([`Valor Total (R$): ${data.total_amount}`])
      xlsxData.push([''])
      xlsxData.push([
        'Se a quantidade de vidas ativas for maior do que o mínimo de vidas em contrato, será considerada a quantidade de vidas ativas e excluídas fora do período de carência para o faturamento final.',
      ])

      setDownload({
        id: item.id,
        data: {
          'Ativos do mês': xlsxDataActive,
          'Excluidos do mês': xlsxDataExcluded,
          'Extrato Geral': xlsxData,
        },
      })

      alertSuccess('Sucesso!! Para fazer o download, clique no botão "baixar"')
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  const month = moment(datesFinancial.start, 'DD/MM/YYYY').format('MM')
  const year = moment(datesFinancial.start, 'DD/MM/YYYY').format('YYYY')

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">{`${company?.legal_name} | Relatórios`}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="align-items-center">
          <Col sm="auto">
            <strong>Período:</strong>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Data inicial</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data inicial',
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({
                      ...dates,
                      start: moment(e).format('YYYY-MM-DD'),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Data final</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data final',
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({ ...dates, end: moment(e).format('YYYY-MM-DD') })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {reports(companyId)?.map((report) => (
            <Col sm={3} className="mb-3" key={report.title}>
              <Card>
                <CardBody style={{ minHeight: 113 }}>
                  <CardTitle className="mb-1 text-sm">{report.title}</CardTitle>
                  <CardText className="text-xs">{report.description}</CardText>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {download.id === report.id && download.data && (
                        <ExcelDownloder
                          data={download.data}
                          filename={`${report.filename}${dates.start}`}
                          type={Type.Button}
                        >
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              setDownload({
                                id: 0,
                                data: null,
                              })
                            }}
                          >
                            <i className="fas fa-download mr-2" />
                            Baixar
                          </Button>
                        </ExcelDownloder>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button size="sm" onClick={() => getReport(report)}>
                        Acessar Relatório
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col sm="auto">
            <strong>Financeiro:</strong>
          </Col>

          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Período</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione o mês e ano',
                  }}
                  dateFormat="MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDatesFinancial({
                      ...datesFinancial,
                      start: moment(e).format('DD/MM/YYYY'),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {monthlyReport(companyId, month, year)?.map((report) => (
            <Col sm={3} className="mb-3" key={report.title}>
              <Card>
                <CardBody style={{ minHeight: 113 }}>
                  <CardTitle className="mb-1 text-sm">{report.title}</CardTitle>
                  <CardText className="text-xs">{report.description}</CardText>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {download.id === report.id && download.data && (
                        <ExcelDownloder
                          data={download.data}
                          filename={`${report.filename}`}
                          type={Type.Button}
                        >
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              setDownload({
                                id: 0,
                                data: null,
                              })
                            }}
                          >
                            <i className="fas fa-download mr-2" />
                            Baixar
                          </Button>
                        </ExcelDownloder>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        size="sm"
                        onClick={() => getMonthlyReport(report)}
                      >
                        Acessar Relatório
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </div>
  )
}

export default ReportsCompany
