const { stringify, parse } = JSON

export const setStorage = <T>(key: string, value: T) => {
  window.localStorage.setItem(key, stringify(value))
}

export const getStorage = (key: string) => {
  const value: any = window.localStorage.getItem(key)
  return parse(value)
}

export const deleteItemStorage = (key: string) => {
  window.localStorage.removeItem(key)
}

export const clearStorage = () => window.localStorage.clear()
