import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'

const ComponentBack = () => {
  const navigation = useHistory()
  return (
    <Container className="mt-3" fluid>
      <Row className="mt-3 align-items-end d-flex justify-content-start">
        <Col sm="auto">
          <Button
            color="primary"
            onClick={() => {
              navigation.goBack()
            }}
          >
            <i className="fas fa-arrow-left" />
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ComponentBack
