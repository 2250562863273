/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Container,
  Col,
  Table,
} from 'reactstrap'
import ReactSpeedometer from 'react-d3-speedometer'

import Loading from 'components/Loading'

import moment from 'moment'
import api from 'configs/api'

const Assessment = ({ select }: any) => {
  const [month, setMonth] = useState(new Date())
  const [countCsat, setCountCsat] = useState(0)
  const [countNps, setCountNps] = useState(0)
  const [comments, setComments] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }

  const getNpsAndCsat = async (value = month) => {
    setLoading(true)
    try {
      const formattedDate = dateFormated(value)
      const response = await api.get(
        `/rating/ms/v1/countries?${formattedDate}&country=${select?.sigla}`
      )

      setComments(response.data.comments)

      const csatValue = response?.data?.csat || 0
      const npsValue = response?.data?.nps || 0

      setCountCsat(Number(csatValue))
      setCountNps(Number(npsValue))
    } catch (e) {
      console.error('Erro ao buscar CSAT e NPS:', e)
    } finally {
      setLoading(false)
    }
  }

  const changeDate = (value: any) => {
    setMonth(value)
    getNpsAndCsat(value)
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  useEffect(() => {
    getNpsAndCsat()
  }, [select])

  return (
    <>
      {loading && <Loading title="Aguarde..." />}
      <Card className="shadow">
        <Container>
          <CardHeader className="mb-4">
            <Row className="justify-content-between">
              <h2>Avaliações</h2>
              <div className="d-flex align-items-center">
                <Button
                  className="btn-icon btn-2"
                  color="primary"
                  size="sm"
                  onClick={() => {
                    changeDate(moment(month).subtract(1, 'month'))
                  }}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
                  {moment(month).format('MMM YYYY')}
                </h4>
                <Button
                  className="btn-icon btn-2 ml-1"
                  color="primary"
                  size="sm"
                  disabled={verifyMonth}
                  onClick={() => {
                    changeDate(moment(month).add(1, 'month'))
                  }}
                >
                  <i className="fa fa-chevron-right" />
                </Button>
              </div>
            </Row>
          </CardHeader>
        </Container>

        <Container className="d-lg-flex justify-content-around">
          <Card className="border-0">
            <CardHeader>
              <Row className=" align-items-center d-flex justify-content-between">
                <div className="col">
                  <h3 className="mb-0">
                    {select?.sigla ? `CSAT ${select?.name}` : 'CSAT País'}
                  </h3>
                </div>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ReactSpeedometer
                value={countCsat}
                maxValue={100}
                needleColor="steelblue"
                needleTransitionDuration={1500}
              />
            </CardBody>
          </Card>

          <Card className="border-0">
            <CardHeader>
              <Row className=" align-items-center d-flex justify-content-between">
                <div className="col">
                  <h3 className="mb-0">
                    {select?.sigla ? `NPS ${select?.name}` : 'NPS País'}
                  </h3>
                </div>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ReactSpeedometer
                value={countNps}
                maxValue={100}
                needleColor="steelblue"
                needleTransitionDuration={1500}
              />
            </CardBody>
          </Card>
        </Container>
      </Card>
      <Card className="shadow mt-4">
        <Container>
          <CardHeader className="mb-4">
            <Row className="justify-content-between">
              <h2>Comentários</h2>
            </Row>
          </CardHeader>
          <Card className="border-0 mb-4">
            <Col className="ml-2">
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>Comentário</th>
                  </tr>
                  {comments?.length > 0 ? (
                    comments.map((comment: any) => (
                      <tr key={comment.id}>
                        <td>{comment.user_name}</td>
                        <td>{comment.user_cellphone}</td>
                        <td>{comment.comment}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>Sem comentários</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Card>
        </Container>
      </Card>
    </>
  )
}

export default Assessment
