/* eslint-disable no-restricted-globals */
export function FormataStringData(data: any): string {
  if (typeof data === 'string' && data.split('/').length === 3) {
    const [dia, mes, ano] = data.split('/')

    // Verifica se dia, mês e ano são números válidos
    if (!isNaN(Number(dia)) && !isNaN(Number(mes)) && !isNaN(Number(ano))) {
      return `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`
    }
  }

  return ''
}
