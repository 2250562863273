import React, { useCallback } from 'react'
import { useLocation, Switch, Redirect } from 'react-router-dom'

// Components
import { Container } from 'reactstrap'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import AdminFooter from 'components/Footers/AdminFooter'
import Sidebar from 'components/Sidebar/Sidebar'

// Routes
import routes from 'routes'

import logoImg from 'assets/img/brand/starbem.svg'
import { PrivateRoute } from './PrivateRoute'

const Admin = (props: any) => {
  const mainContent = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    // @ts-ignore
    document.scrollingElement.scrollTop = 0
    // @ts-ignore
    mainContent.current.scrollTop = 0
  }, [location])

  const getRoutes = useCallback(
    (routesProp: any) =>
      routesProp.map((prop: any) => {
        if (prop.layout === '/admin') {
          return (
            <PrivateRoute
              props={prop}
              path={prop.layout + prop.path}
              component={prop.component}
              key={prop.layout}
              // eslint-disable-next-line react/destructuring-assignment
              exact={props.exact}
            />
          )
        }
        return null
      }),
    []
  )

  const getBrandText = () => {
    /* eslint-disable-next-line */
    for (let i = 0; i < routes.length; i++) {
      if (
        // eslint-disable-next-line react/destructuring-assignment
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
    return props.location.pathname.split('/')[2]
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: '/admin/index',
          imgSrc: logoImg,
          imgAlt: '...',
        }}
      />
      <div
        className="main-content"
        ref={mainContent}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
        }}
      >
        <AdminNavbar
          {...props}
          // @ts-ignore
          // eslint-disable-next-line
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default Admin
