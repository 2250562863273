import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik, FormikProvider } from 'formik'
import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
  Spinner,
} from 'reactstrap'

const validationSchema = yup.object().shape({
  question: yup.string().required('Informe a pergunta!'),
  answer: yup.string().required('Informe a resposta!'),
  type: yup.string(),
})

const ModalFaq = ({ modal, setModal, action, faq }: any) => {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
      type: 'app',
      lang: 'pt-BR',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (faq?.id) {
          await api.put(`backoffice/ms/v1/faq/${faq.id}`, {
            ...values,
            status: 'active',
          })
          alertSuccess('FAQ alterada com sucesso.')
        } else {
          await api.post(`backoffice/ms/v1/faqs`, values)
          alertSuccess('FAQ cadastrada com sucesso.')
        }
        formik.resetForm()
        action()
      } catch (err: any) {
        alertWarning(err?.response?.data?.message || 'Erro ao processar FAQ')
      } finally {
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    if (faq?.id) {
      formik.setValues({
        question: faq.question || '',
        answer: faq.answer || '',
        type: faq.type || 'app',
        lang: faq.lang || 'pt-BR',
      })
    }
  }, [faq])

  // Função para fechar a modal
  const toggle = () => {
    if (!loading) {
      formik.resetForm()
      setModal(!modal)
    }
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={loading ? 'static' : true}
      centered
    >
      <ModalHeader
        toggle={!loading ? toggle : undefined}
        close={
          !loading ? (
            <button type="button" className="close">
              &times;
            </button>
          ) : null
        }
      >
        <h3>{faq?.id ? 'Editar FAQ' : 'Cadastrar nova FAQ'}</h3>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center">
            <Spinner color="primary" />
            <p>Carregando...</p>
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label for="question">Pergunta</Label>
                    <Input
                      type="textarea"
                      name="question"
                      id="question"
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.question}
                      invalid={
                        !!formik.errors.question && !!formik.touched.question
                      }
                    />
                    {formik.errors.question && formik.touched.question && (
                      <div className="input-feedback">
                        {formik.errors.question}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label for="answer">Resposta</Label>
                    <Input
                      type="textarea"
                      name="answer"
                      id="answer"
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.answer}
                      invalid={
                        !!formik.errors.answer && !!formik.touched.answer
                      }
                    />
                    {formik.errors.answer && formik.touched.answer && (
                      <div className="input-feedback">
                        {formik.errors.answer}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label for="type">Tipo</Label>
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.type}
                    >
                      <option value="app">Aplicativo</option>
                      <option value="app-internacional">App Global</option>
                      <option value="site">Web Site</option>
                      <option value="site-internacional">
                        Web Site Internacional
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label for="lang">Linguagem</Label>
                    <Input
                      type="select"
                      name="lang"
                      id="lang"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.lang}
                    >
                      <option value="pt-BR">Português</option>
                      <option value="en">Inglês</option>
                      <option value="es">Espanhol</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col sm="6">
                  <Button
                    outline
                    color="secondary"
                    onClick={toggle}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm="6">
                  <Button type="submit" color="secondary" disabled={loading}>
                    {faq?.id ? 'Alterar' : 'Cadastrar'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalFaq
