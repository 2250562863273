export function formatName(nameComplete: any) {
  if (!nameComplete || typeof nameComplete !== 'string') {
    return ''
  }

  const partesNome = nameComplete.trim().split(' ')

  if (partesNome.length >= 2) {
    const primeiroNome = partesNome[0]
    const ultimoNome = partesNome[partesNome.length - 1]
    return `${primeiroNome} ${ultimoNome}`
  }

  return nameComplete
}
