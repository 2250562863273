import React, { useState, useMemo, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import {
  Card,
  CardBody,
  Container,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'
import ComponentBack from 'components/ComponentBack'
import InputPassword from 'components/InputPassword'
import api from 'configs/api'
import { FormikProvider, useFormik } from 'formik'
import { cpfCnpjMask, cpfCnpjUnmask } from 'js-essentials-functions'
import { alertDanger, alertSuccess } from 'utils/toast'
import * as yup from 'yup'

const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  document: yup.string().required('Informe o CPF!').min(14, 'CPF inválido!'),
  password: yup
    .string()
    .required('Informe uma senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
  passwordConfirmation: yup
    .string()
    .required('Confirme a senha!')
    .oneOf([yup.ref('password'), null], 'As senhas devem corresponder'),
})
const schema = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  document: yup.string().required('Informe o CPF!').min(11, 'CPF inválido!'),
})

const NewUser = () => {
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState(false)
  const { state }: any = useLocation()
  const history = useHistory()
  const initialValuesNew = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      document: '',
    }),
    []
  )
  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      document: '',
    }),
    []
  )

  const formikNew = useFormik({
    initialValues: initialValuesNew,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await api.post('/manager/health-post/user', {
          prefecture_uuid: state?.params?.prefectureId,
          health_post_uuid: state?.params?.uuidPost,
          name: values.name,
          document: cpfCnpjUnmask(values.document),
          email: values.email,
          password: values.password,
        })
        alertSuccess('Usuário cadastrado com sucesso')
        setLoading(false)
        history.goBack()
      } catch (err: any) {
        alertDanger(err.response.data.message || 'Erro')
        setEditable(false)
        setLoading(false)
      }
    },
  })
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await api.put(`/manager/health-post/user/${state?.user?.id}`, {
          name: values.name,
          document: cpfCnpjUnmask(values.document),
          email: values.email,
        })
        alertSuccess('Usuário atualizado com sucesso!')
        setLoading(false)
        history.goBack()
      } catch (err: any) {
        alertDanger(err.response.data.message || 'Erro')
        setLoading(false)
      }
      setEditable(false)
    },
  })

  useEffect(() => {
    if (state.user) {
      formik.setValues(state.user)
    }
  }, [])

  const formikRegister = () => (
    <FormikProvider value={formikNew}>
      <Form onSubmit={formikNew.handleSubmit}>
        <Row className="align-items-center justify-content-between mb-2">
          <Col sm="9" className="mb-2">
            <h3 className="text-black">Cadastrar Usuário</h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Nome do usuário
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder=""
                name="name"
                type="text"
                onBlur={formikNew.handleBlur}
                onChange={formikNew.handleChange}
                value={formikNew.values.name}
                error={!!formikNew.errors.name}
              />
              {formikNew.errors.name && formikNew.touched.name && (
                <div className="input-feedback">{formikNew.errors.name}</div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <Input
                className="form-control-alternative"
                id="input-email"
                placeholder=""
                name="email"
                type="text"
                autoComplete="off"
                onBlur={formikNew.handleBlur}
                onChange={formikNew.handleChange}
                value={formikNew.values.email}
                error={!!formikNew.errors.email}
              />
              {formikNew.errors.email && formikNew.touched.email && (
                <div className="input-feedback">{formikNew.errors.email}</div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                CPF
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder=""
                name="document"
                type="text"
                maxLength={14}
                onBlur={formikNew.handleBlur}
                onChange={formikNew.handleChange}
                value={cpfCnpjMask(formikNew.values.document)}
                error={!!formikNew.errors.document}
              />
              {formikNew.errors.document && formikNew.touched.document && (
                <div className="input-feedback">
                  {formikNew.errors.document}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="password">
                Senha
              </label>
              <InputPassword
                className="form-control-alternative"
                id="password"
                placeholder=""
                name="password"
                type="password"
                autoComplete="new-password"
                onBlur={formikNew.handleBlur}
                onChange={formikNew.handleChange}
                value={formikNew.values.password}
                error={!!formikNew.errors.password}
              />
              {formikNew.errors.password && formikNew.touched.password && (
                <div className="input-feedback">
                  {formikNew.errors.password}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="passwordConfirmation"
              >
                Confirmar senha
              </label>
              <InputPassword
                className="form-control-alternative"
                id="passwordConfirmation"
                placeholder=""
                name="passwordConfirmation"
                type="password"
                onBlur={formikNew.handleBlur}
                onChange={formikNew.handleChange}
                value={formikNew.values.passwordConfirmation}
                error={!!formikNew.errors.passwordConfirmation}
              />
              {formikNew.errors.passwordConfirmation &&
                formikNew.touched.passwordConfirmation && (
                  <div className="input-feedback">
                    {formikNew.errors.passwordConfirmation}
                  </div>
                )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="auto" className="mb-2">
            <Button
              sm="auto"
              color="primary"
              disabled={!formikNew.isValid}
              type="submit"
            >
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
  const formikUpdate = () => (
    <FormikProvider value={formik}>
      <Form>
        <Row className="align-items-center justify-content-between mb-2">
          <Col sm="9" className="mb-2">
            <h3 className="text-black">Usuário</h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Nome do usuário
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder=""
                name="name"
                type="text"
                disabled={!editable}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name}
              />
              {formik.errors.name && formik.touched.name && (
                <div className="input-feedback">{formik.errors.name}</div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <Input
                className="form-control-alternative"
                id="input-email"
                placeholder=""
                name="email"
                type="text"
                autoComplete="off"
                disabled={!editable}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={!!formik.errors.email}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="input-feedback">{formik.errors.email}</div>
              )}
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                CPF
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder=""
                name="document"
                type="text"
                maxLength={14}
                disabled
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={cpfCnpjMask(formik.values.document)}
                error={!!formik.errors.document}
              />
              {formik.errors.document && formik.touched.document && (
                <div className="input-feedback">{formik.errors.document}</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col sm="auto" className="mb-2">
            {editable ? (
              <Button
                sm="auto"
                color="primary"
                disabled={!formik.isValid}
                onClick={() => formik.handleSubmit()}
              >
                Atualizar
              </Button>
            ) : (
              <Button
                sm="auto"
                color="primary"
                onClick={() => setEditable(true)}
              >
                Editar
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )

  if (loading) return <Loading title="Carregando..." />
  return (
    <div>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardBody>{state.user ? formikUpdate() : formikRegister()}</CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default NewUser
