import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Button, Row } from 'reactstrap'
import ReactSpeedometer from 'react-d3-speedometer'

import moment from 'moment'
import api from 'configs/api'

const ChartCsat = () => {
  const [month, setMonth] = useState(new Date())
  const [countCsat, setCountCsat] = useState(0)

  const dateFormated = (value: Date) => {
    const dayInit = moment(value).format('01/MM/YYYY')
    const lastDay = moment.utc(value).daysInMonth()
    const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
    return `start=${dayInit}&end=${dayEnd}`
  }

  const getCsat = async (value = month) => {
    try {
      const { data } = await api.get(
        `rating/ms/v1/countries?country=all&${dateFormated(value)}`
      )
      setCountCsat(data?.csat)
    } catch (e) {
      console.log(e)
    }
  }
  const changeDate = (value: any) => {
    setMonth(value)
    getCsat(value)
  }
  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  useEffect(() => {
    getCsat()
  }, [])

  return (
    <Card>
      <CardHeader>
        <Row className=" align-items-center d-flex justify-content-between">
          <div className="col">
            <h3 className="mb-0">CSAT</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              className="btn-icon btn-2"
              color="primary"
              size="sm"
              onClick={() => {
                changeDate(moment(month).subtract(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-left" />
            </Button>
            <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
              {moment(month).format('MMM YYYY')}
            </h4>
            <Button
              className="btn-icon btn-2 ml-1"
              color="primary"
              size="sm"
              disabled={verifyMonth}
              onClick={() => {
                changeDate(moment(month).add(1, 'month'))
              }}
            >
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </Row>
      </CardHeader>
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ReactSpeedometer
          value={countCsat}
          maxValue={100}
          needleColor="steelblue"
          needleTransitionDuration={1500}
        />
      </CardBody>
    </Card>
  )
}
export default ChartCsat
