/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import api from 'configs/api'
import { alertSuccess, alertWarning } from 'utils/toast'

import {
  Button,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import Loading from 'components/Loading'
import useSpecialty from 'hooks/useSpecialty'
import {
  SettingsCompany,
  CollaboratorsCompany,
  AdminsCompany,
  ReportsCompany,
  FinancialCompany,
} from './Tabs'

const Company = () => {
  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState<any>()
  const [specialties, setSpecialties] = useState<any[]>([])
  const [notAccess, setNotAccess] = useState(true)
  const [tabs, setTabs] = useState(1)

  const params: any = useLocation()
  const navigation = useHistory()
  const { getSpecialties } = useSpecialty()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]

  const getCompany = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/company/ms/v1/companies/${companyId}`)

      const item = response?.data

      await getSpecialties()
      setIsActive(item.status === 'active')
      const arrayAreaId: any = []
      item?.specialties?.map((area: any) => {
        arrayAreaId.push(area.specialty_id)
      })
      setSpecialties(item.status === 'active' ? arrayAreaId : [])
      setCompany(item)
    } catch (err: any) {
      console.log('error', err?.response?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCompany()
  }, [])

  const handleDelete = async () => {
    setLoading(true)
    try {
      const res = await api.delete(`/company/ms/v1/companies/${companyId}`)
      getCompany()
      alertSuccess(res?.data?.message || 'Sucesso!')
      navigation.push('/admin/empresas')
    } catch (err) {
      alertWarning('Ocorreu um error ao excluir a empresa')
    }
    setLoading(false)
  }

  const handleStatus = async () => {
    setLoading(true)
    try {
      const res = await api.post(
        `/company/ms/v1/companies/status/${companyId}`,
        {
          status: isActive ? 'inactive' : 'active',
        }
      )
      getCompany()
      alertSuccess(res?.data?.message || 'Sucesso!')
    } catch (err) {
      alertWarning('Ocorreu um error tente novamente!')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (
      !company?.cellphone ||
      !company?.email ||
      !company?.zipcode ||
      !company?.state ||
      !company?.plan_id ||
      !company?.street ||
      !company?.city
    ) {
      setNotAccess(false)
    } else {
      setNotAccess(true)
    }
  }, [company])

  return (
    <div>
      <HeaderNeutro />
      {loading && <Loading title="Carregando..." />}
      <Container fluid>
        <Row className="align-items-end d-flex justify-content-between">
          <Col sm="auto" className="align-items-start ">
            <Button
              color="secondary"
              onClick={() => {
                navigation.push('/admin/empresas')
              }}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 1,
                })}
                disabled={!isActive}
                onClick={() => setTabs(1)}
                style={!isActive ? { opacity: 0.7 } : {}}
                href="#Configurações"
                role="tab"
              >
                <i className="ni ni-settings-gear-65 mr-2" />
                Configurações
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 2,
                })}
                disabled={!isActive}
                onClick={() => setTabs(2)}
                style={!isActive ? { opacity: 0.7 } : {}}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-users mr-2" />
                Colaboradores
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 3}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 3,
                })}
                disabled={!isActive}
                onClick={() => setTabs(3)}
                style={!isActive ? { opacity: 0.7 } : {}}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-user-tie mr-2" />
                Administradores
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 4}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 4,
                })}
                disabled={!isActive}
                onClick={() => setTabs(4)}
                style={!isActive ? { opacity: 0.7 } : {}}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-file-alt mr-2" />
                Relatórios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 5}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: tabs === 5,
                })}
                disabled={!isActive || !notAccess}
                onClick={() => setTabs(5)}
                style={!isActive || !notAccess ? { opacity: 0.7 } : {}}
                href="#Configurações"
                role="tab"
              >
                <i className="fas fa-coins mr-2" />
                Financeiro
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="shadow">
          <CardBody className="p-0">
            <TabContent activeTab={`tabs${tabs}`}>
              <TabPane tabId="tabs1">
                {tabs === 1 && (
                  <SettingsCompany
                    company={company}
                    specialties={specialties}
                    isActive={isActive}
                    handleDelete={handleDelete}
                    handleStatus={handleStatus}
                    accessFinancial={notAccess}
                  />
                )}
              </TabPane>
              <TabPane tabId="tabs2">
                {tabs === 2 && <CollaboratorsCompany company={company} />}
              </TabPane>
              <TabPane tabId="tabs3">
                {tabs === 3 && <AdminsCompany company={company} />}
              </TabPane>
              <TabPane tabId="tabs4">
                {tabs === 4 && <ReportsCompany company={company} />}
              </TabPane>
              <TabPane tabId="tabs5">
                {tabs === 5 && <FinancialCompany company={company} />}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Company
