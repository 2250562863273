/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import Loading from 'components/Loading'
import { alertDanger, alertSuccess } from 'utils/toast'
import api from 'configs/api'
import ModalTemas from '../Components/ModalTemas'

const Temas = () => {
  const [modalDel, setModalDel] = useState<any>('')
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [themeEdit, setThemeEdit] = useState<any>(null)
  const [data, setData] = useState<any>([])

  const closeDeleteModal = () => {
    setModalDel('')
  }

  const getTemas = async () => {
    setLoading(true)
    try {
      const res = await api.get('manager/starzen/audios/categories')
      setData(res.data)
    } catch (err) {
      console.log(err)
      alertDanger('Erro ao carregar os temas.')
    }
    setLoading(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await api.delete(`/manager/starzen/audios/categories/${modalDel}`)
      getTemas()
      closeDeleteModal()
      alertSuccess('Tema excluído com sucesso!')
    } catch (error) {
      console.error(error)
      alertDanger('Erro ao excluir o tema. Por favor, tente novamente.')
    }
    setLoading(false)
  }

  useEffect(() => {
    getTemas()
  }, [])

  const modalDelete = () => (
    <Modal isOpen={modalDel} toggle={closeDeleteModal}>
      <ModalHeader className="text-center align-items-center justify-content-center">
        <h2 className="mb-0">REMOVER TEMA</h2>
      </ModalHeader>
      <ModalBody>
        <h4 className="text-center">Deseja realmente remover esse tema?</h4>
        <Row className="d-flex justify-content-center mt-3">
          <Col sm="6">
            <Button color="white" onClick={closeDeleteModal} block>
              Cancelar
            </Button>
          </Col>
          <Col sm="6">
            <Button color="danger" onClick={handleDelete} block>
              Remover
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  return (
    <Card className="shadow">
      {loading && <Loading title="Carregando..." />}
      <CardHeader className="bg-transparent border-0">
        <Row className="align-items-center">
          <Col sm="9" className="mb-2">
            <h3 className="mb-0 text-black">Temas dos áudios</h3>
          </Col>

          <Col sm="auto" className="ml-auto">
            <Button
              sm="auto"
              color="primary"
              onClick={() => setModalOpen(true)}
              className="mt-2 float-right"
            >
              <i className="fas fa-plus" />
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <Row className="mt-0">
        <div className="col">
          <Card className="shadow">
            <Table className="align-items-center table-flush" responsive hover>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Ícone</th>
                  <th scope="col">Nome</th>
                  <th scope="col" className="text-right pr-50">
                    <span className="mr-4">Ação</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((tema: any) => (
                  <tr key={tema._id}>
                    <td style={{ width: '50px' }}>
                      <img
                        src={tema.icon}
                        alt="Ícone"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </td>
                    <td>{tema.name}</td>
                    <td className="d-flex align-items-center justify-content-end">
                      <div style={{ width: 30, marginLeft: 10 }}>
                        <Button
                          size="sm"
                          color="white"
                          className="border #D0D5DD"
                          onClick={() => {
                            setThemeEdit(tema)
                            setModalOpen(true)
                          }}
                        >
                          <i className="fa fa-pen" />
                        </Button>
                      </div>
                      <div style={{ width: 30, marginLeft: 10 }}>
                        <Button
                          size="sm"
                          color="white"
                          className="border border-danger"
                          onClick={() => setModalDel(tema._id)}
                        >
                          <i className="far fa-trash-alt text-danger" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>
      {modalOpen && (
        <ModalTemas
          modal={modalOpen}
          setModal={setModalOpen}
          setLoading={setLoading}
          getTemas={getTemas}
          theme={themeEdit}
          setThemeEdit={setThemeEdit}
        />
      )}

      {modalDelete()}
    </Card>
  )
}

export default Temas
