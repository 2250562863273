import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap'

/* eslint-disable-next-line */
import profileImg from 'assets/img/brand/avatar.jpg'
import { useAuth } from 'context/authentication/auth.context'
import PermissionComponent from 'components/PermissionComponent'

const AdminNavbar = ({ brandText }: any) => {
  const { logout, adminData, verifyAdminStorage, verifyUser } = useAuth()
  const history = useHistory()
  const handleLogout = () => {
    logout()
    history.push('/')
  }
  useEffect(() => {
    verifyAdminStorage()
    verifyUser()
  }, [])

  return (
    <>
      <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main">
        <Container fluid>
          <span
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            // to="/admin/index"
          >
            {brandText}
          </span>
          <Nav className="align-items-center d-none d-lg-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="..." src={profileImg} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {adminData.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem Vindo!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Meu Perfil</span>
                </DropdownItem>
                <PermissionComponent role="SUPERADMIN">
                  <DropdownItem to="/admin/admin" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Administração</span>
                  </DropdownItem>
                </PermissionComponent>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}
export default AdminNavbar
