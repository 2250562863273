import React, { useEffect, useState } from 'react'

import { getStorage } from 'utils/storage'

interface PermissionComponentProps {
  role: string
  children: React.ReactNode
}

const PermissionComponent = ({ role, children }: PermissionComponentProps) => {
  const [permissions, setPermissions] = useState<boolean>()

  useEffect(() => {
    const loadRole = () => {
      const response = getStorage('@ManagerStarbem:user')
      if (response) {
        const data = response
        const findRole = data?.roles.some((r: string) =>
          role?.split(',').includes(r)
        )
        if (!role) {
          setPermissions(true)
        } else {
          setPermissions(findRole)
        }
      }
    }
    loadRole()
  }, [])
  return <>{permissions ? children : null}</>
}

export default PermissionComponent
