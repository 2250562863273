/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from 'react'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  dateMask,
  dateMaskUS,
  telephoneMask,
  telephoneUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import { Field, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'

// Components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'

import { alertDanger, alertSuccess } from 'utils/toast'
import { createDoctor } from 'services/doctor'
import Uf from 'utils/Uf'
import Loading from 'components/Loading'
import InputPassword from 'components/InputPassword'
import ComponentBack from 'components/ComponentBack'
import useSpecialty from 'hooks/useSpecialty'
import useFetch from 'hooks/useFetch'

const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o nome!'),
  email: yup
    .string()
    .required('Informe o email!')
    .email('Informe um email valido!'),
  cpf: yup
    .string()
    .required('Informe o CPF!')
    .min(14, 'CPF inválido!')
    .test('test', 'CPF inválido!', (value) => {
      if (!value) return false
      return cpfValidator(value)
    }),
  password: yup
    .string()
    .required('Informe uma senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
  cellphone: yup.string().required('Informe o telefone!'),
  selectTypeDoc: yup.string().required('Informe o tipo de documento!'),
  gender: yup.string().required('Informe o gênero!'),
  birthDay: yup.string(),
  selectUf: yup.string().required('Selecione o Estado!'),
  numberDoc: yup.string().required('Informe o Número do Documento!'),
})
const genders = [
  {
    label: 'Masculino',
    key: 'male',
  },
  {
    label: 'Feminino',
    key: 'female',
  },
]

const NewDoctor = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { getSpecialties, filterSpecialtiesActive } = useSpecialty()
  const history = useHistory()

  const [selectedLanguage, setSelectedLanguage] = useState<any>([])
  const [groups, setGroups] = useState<any>([])

  const [selectedTags, setSelectedTags] = useState<any>([])

  const { data } = useFetch(`accounts/v1/countries`)
  const { data: dataTags } = useFetch(`doctors/v1/psicologist-tags`)
  const { data: plansGroups } = useFetch(`doctors/ms/v1/plan-groups`)

  const handleTagSelection = (tagId: any) => {
    const index = selectedTags.indexOf(tagId)
    if (index === -1 && selectedTags.length < 4) {
      setSelectedTags([...selectedTags, tagId])
    } else if (index !== -1) {
      setSelectedTags(selectedTags.filter((id: any) => id !== tagId))
    }
  }

  const countries = data?.map((pais: any) => pais?.sigla)
  const filteredCountriesUS = countries?.filter(
    (country: any) => country !== 'BR'
  )

  const filteredCountries = useMemo(() => {
    if (selectedLanguage.includes('BR') && selectedLanguage.includes('US')) {
      return countries
    }
    if (selectedLanguage.includes('BR')) {
      return ['PT', 'BR']
    }
    if (selectedLanguage.includes('US')) {
      return filteredCountriesUS
    }

    return []
  }, [selectedLanguage])

  const initialValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      cellphone: '',
      birthDay: '',
      cpf: '',
      selectTypeDoc: '',
      selectUf: '',
      numberDoc: '',
      gender: '',
      bio: '',
      specialties: [],
      countries: [],
      tags: [],
    }),
    []
  )

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values) => {
      setLoading(true)
      const datas = {
        email: values.email,
        name: values.name.trim(),
        password: values.password,
        document: cpfCnpjUnmask(values.cpf),
        cellphone: telephoneUnmask(values.cellphone),
        gender: values.gender,
        birthDay: dateMaskUS(values.birthDay),
        professionalDocumentType: values.selectTypeDoc,
        professionalDocumentUf: values.selectUf,
        professionalDocumentNumber: values.numberDoc,
        specialties: values.specialties?.map((specialty) => ({
          id: specialty,
          status: 1,
        })),
        bio: values.bio,
        countries: filteredCountries,
        groups,
        tags: values.selectTypeDoc === 'CRP' ? selectedTags : [],
      }

      try {
        const response = await createDoctor(datas)

        if (response.status) {
          alertSuccess('Médico cadastrado com sucesso')
          formik.resetForm()
          setSelectedTags([])
          history.push('/admin/medicos')
        } else {
          alertDanger(response.msg || 'Erro ao cadastrar o Médico')
        }
      } catch (error) {
        alertDanger('Ocorreu um erro no cadastro')
      } finally {
        setLoading(false)
      }
    },
  })

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!loading) {
      formik.handleSubmit()
    }
  }

  const handleCountryChange = (e: any) => {
    const selectedLanguage = e.target.value
    setSelectedLanguage((prevLanguages: any) => {
      if (prevLanguages.includes(selectedLanguage)) {
        return prevLanguages.filter((lang: any) => lang !== selectedLanguage)
      }

      if (prevLanguages.length === 2) {
        return [prevLanguages[1], selectedLanguage]
      }
      return [...prevLanguages, selectedLanguage]
    })
  }
  const handleGroupChange = (e: any) => {
    const selectedGroups = e.target.value
    setGroups((prevLanguages: any) => {
      if (prevLanguages.includes(selectedGroups)) {
        return prevLanguages.filter((lang: any) => lang !== selectedGroups)
      }

      if (prevLanguages.length === 2) {
        return [prevLanguages[1], selectedGroups]
      }
      return [...prevLanguages, selectedGroups]
    })
  }

  useEffect(() => {
    getSpecialties()
  }, [])

  if (loading) return <Loading title="Carregando..." />

  return (
    <>
      <HeaderNeutro />
      <ComponentBack />
      <Container className="mt-3" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-white shadow">
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Informe os dados abaixo para cadastrar um novo profissional da
                  saúde
                </h6>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-namedoctor"
                            >
                              Nome
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-namedoctor"
                              placeholder="Digite o nome.."
                              name="name"
                              type="text"
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={(e) => formik.handleChange(e)}
                              value={formik.values.name}
                              error={!!formik.errors.name}
                            />
                            {formik.errors.name && formik.touched.name && (
                              <div className="input-feedback">
                                {formik.errors.name}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Digite o email..."
                              type="text"
                              name="email"
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              error={!!formik.errors.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <div className="input-feedback">
                                {formik.errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-birthDay"
                            >
                              Data de nascimento
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-birthDay"
                              placeholder=""
                              type="text"
                              name="birthDay"
                              maxLength={10}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={dateMask(formik.values.birthDay)}
                              error={!!formik.errors.birthDay}
                            />
                            {formik.errors.birthDay &&
                              formik.touched.birthDay && (
                                <div className="input-feedback">
                                  {formik.errors.birthDay}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              placeholder="000.000.000-00"
                              type="text"
                              name="cpf"
                              maxLength={14}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={cpfCnpjMask(formik.values.cpf)}
                              error={!!formik.errors.cpf}
                            />
                            {formik.errors.cpf && formik.touched.cpf && (
                              <div className="input-feedback">
                                {formik.errors.cpf}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-cellphone"
                            >
                              Celular
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="input-cellphone"
                              placeholder="Digite o Celular..."
                              type="text"
                              name="cellphone"
                              maxLength={15}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={telephoneMask(formik.values.cellphone)}
                              error={!!formik.errors.cellphone}
                            />
                            {formik.errors.cellphone &&
                              formik.touched.cellphone && (
                                <div className="input-feedback">
                                  {formik.errors.cellphone}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="selectTypeDoc"
                            >
                              Tipo do documento
                            </Label>
                            <Input
                              type="select"
                              id="selectTypeDoc"
                              name="selectTypeDoc"
                              value={formik.values.selectTypeDoc}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option selected>Selecione...</option>
                              <option value="CRM">CRM</option>
                              <option value="CRP">CRP</option>
                              <option value="CRN">CRN</option>
                            </Input>
                            {formik.errors.selectTypeDoc &&
                              formik.touched.selectTypeDoc && (
                                <div className="input-feedback">
                                  {formik.errors.selectTypeDoc}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="input-numero-doc"
                            >
                              Número documento
                            </Label>
                            <Input
                              className="form-control-alternative"
                              id="numberDoc"
                              placeholder=""
                              type="text"
                              name="numberDoc"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.numberDoc}
                              error={!!formik.errors.numberDoc}
                            />
                            {formik.errors.numberDoc &&
                              formik.touched.numberDoc && (
                                <div className="input-feedback">
                                  {formik.errors.numberDoc}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="select-uf"
                            >
                              UF
                            </Label>

                            <Input
                              type="select"
                              id="selectUf"
                              name="selectUf"
                              value={formik.values.selectUf}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option defaultValue="">Selecione...</option>
                              {Uf.map((e) => (
                                <option key={e} value={e} label={e} />
                              ))}
                            </Input>
                            {formik.errors.selectUf &&
                              formik.touched.selectUf && (
                                <div className="input-feedback">
                                  {formik.errors.selectUf}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label
                              className="form-control-label"
                              htmlFor="gender"
                            >
                              Gênero
                            </Label>

                            <Input
                              type="select"
                              id="gender"
                              name="gender"
                              value={formik.values.gender}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value="default" selected>
                                Gênero...
                              </option>
                              {genders.map((e) => (
                                <option
                                  key={e.key}
                                  value={e.key}
                                  label={e.label}
                                />
                              ))}
                            </Input>
                            {formik.errors.gender && formik.touched.gender && (
                              <div className="input-feedback">
                                {formik.errors.gender}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Label
                                className="form-control-label"
                                htmlFor="input-password"
                              >
                                Senha
                              </Label>
                            </div>
                            <InputPassword
                              className="form-control-alternative"
                              id="input-password"
                              placeholder=""
                              name="password"
                              autoComplete="new-password"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              error={!!formik.errors.password}
                            />
                            {formik.errors.password &&
                              formik.touched.password && (
                                <div className="input-feedback">
                                  {formik.errors.password}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Label className="form-control-label">Bio</Label>
                          <Input
                            type="textarea"
                            id="bio"
                            name="bio"
                            value={formik.values.bio}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="auto">
                          <h3 className="form-control-label">
                            Área de atuação
                          </h3>
                          <div className="overflow-auto d-flex ">
                            <FormGroup className="d-flex flex-wrap">
                              {filterSpecialtiesActive().map((specialty) => (
                                <Label
                                  className="mr-3 align-items-center justify-content-center"
                                  size="sm"
                                  key={specialty.id}
                                >
                                  <Field
                                    name="specialties"
                                    type="checkbox"
                                    className="mr-2 align-items-center justify-content-center"
                                    value={specialty.id}
                                  />
                                  {specialty.name}
                                </Label>
                              ))}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      {plansGroups?.length > 0 && (
                        <Row>
                          <Col lg="auto">
                            <h3 className="form-control-label">
                              Formato de atendimento
                            </h3>
                            <FormGroup
                              className="d-flex flex-wrap"
                              onChange={(e) => handleGroupChange(e)}
                            >
                              {plansGroups.map((item: any) => (
                                <Label
                                  className="mr-3 align-items-center justify-content-center"
                                  size="sm"
                                  key={item}
                                >
                                  <Field
                                    name="countries"
                                    type="checkbox"
                                    className="mr-2 align-items-center justify-content-center"
                                    value={item}
                                  />
                                  {item}
                                </Label>
                              ))}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col lg="auto">
                          <h3 className="form-control-label">
                            Idiomas atendidos
                          </h3>
                          <FormGroup
                            className="d-flex flex-wrap"
                            onChange={(e) => handleCountryChange(e)}
                          >
                            <Label
                              className="mr-3 align-items-center justify-content-center"
                              size="sm"
                              key="BR"
                            >
                              <Field
                                name="countries"
                                type="checkbox"
                                className="mr-2 align-items-center justify-content-center"
                                value="BR"
                              />
                              Português
                            </Label>
                            <Label
                              className="mr-3 align-items-center justify-content-center"
                              size="sm"
                              key="US"
                            >
                              <Field
                                name="countries"
                                type="checkbox"
                                className="mr-2 align-items-center justify-content-center"
                                value="US"
                              />
                              Inglês
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>

                      {formik.values.selectTypeDoc === 'CRP' && (
                        <Row>
                          <Col lg="auto">
                            <h3 className="form-control-label">
                              Especialidade (máx. 4)
                            </h3>
                            <FormGroup className="d-flex flex-wrap">
                              {dataTags &&
                                dataTags.map((tag: any) => {
                                  const isDisabled =
                                    selectedTags.length >= 4 &&
                                    !selectedTags.includes(tag.id)
                                  const isChecked = selectedTags.includes(
                                    tag.id
                                  )
                                  return (
                                    <Label
                                      className="mr-3 align-items-center justify-content-center"
                                      size="sm"
                                      key={tag.id}
                                    >
                                      <Field
                                        name="tags"
                                        type="checkbox"
                                        className="mr-2 align-items-center justify-content-center"
                                        value={tag.id}
                                        checked={isChecked}
                                        onChange={() =>
                                          handleTagSelection(tag.id)
                                        }
                                        disabled={isDisabled}
                                      />
                                      {tag.title}
                                    </Label>
                                  )
                                })}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row className="d-flex justify-content-end">
                        <Col lg="3">
                          <Button
                            color="warning"
                            type="submit"
                            disabled={!formik.isValid || loading}
                          >
                            {loading ? 'Carregando...' : 'Cadastrar'}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NewDoctor
