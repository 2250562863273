export const department = [
  {
    key: 1,
    value: '',
    title: 'Selecione...',
  },
  {
    key: 2,
    value: 'Administração',
    title: 'Administração',
  },
  {
    key: 3,
    value: 'Recursos Humanos (RH)',
    title: 'Recursos Humanos (RH)',
  },
  {
    key: 4,
    value: 'Finanças',
    title: 'Finanças',
  },
  {
    key: 5,
    value: 'Contabilidade',
    title: 'Contabilidade',
  },
  {
    key: 6,
    value: 'Jurídico',
    title: 'Jurídico',
  },
  {
    key: 7,
    value: 'Marketing',
    title: 'Marketing',
  },
  {
    key: 8,
    value: 'saVendasles',
    title: 'Vendas',
  },
  {
    key: 9,
    value: 'Atendimento ao Cliente',
    title: 'Atendimento ao Cliente',
  },
  {
    key: 10,
    value: 'Compras',
    title: 'Compras',
  },
  {
    key: 11,
    value: 'Logística',
    title: 'Logística',
  },
  {
    key: 12,
    value: 'Operações',
    title: 'Operações',
  },
  {
    key: 13,
    value: 'Tecnologia da Informação (TI)',
    title: 'Tecnologia da Informação (TI)',
  },
  {
    key: 14,
    value: 'Desenvolvimento de Produtos',
    title: 'Desenvolvimento de Produtos',
  },
  {
    key: 15,
    value: 'Pesquisa e Desenvolvimento (P&D)',
    title: 'Pesquisa e Desenvolvimento (P&D)',
  },
  {
    key: 16,
    value: 'Produção/Manufatura',
    title: 'Produção/Manufatura',
  },
  {
    key: 17,
    value: 'Qualidade',
    title: 'Qualidade',
  },
  {
    key: 18,
    value: 'Engenharia',
    title: 'Engenharia',
  },
  {
    key: 19,
    value: 'Segurança e Saúde no Trabalho',
    title: 'Segurança e Saúde no Trabalho',
  },
  {
    key: 20,
    value: 'Sustentabilidade e Meio Ambiente',
    title: 'Sustentabilidade e Meio Ambiente',
  },
  {
    key: 21,
    value: 'Relações Públicas',
    title: 'Relações Públicas',
  },
  {
    key: 22,
    value: 'Comunicação Interna',
    title: 'Comunicação Interna',
  },
  {
    key: 23,
    value: 'Treinamento e Desenvolvimento',
    title: 'Treinamento e Desenvolvimento',
  },
  {
    key: 24,
    value: 'Auditoria',
    title: 'Auditoria',
  },
  {
    key: 25,
    value: 'Planejamento Estratégico',
    title: 'Planejamento Estratégico',
  },
  {
    key: 26,
    value: 'Compliance (Conformidade)',
    title: 'Compliance (Conformidade)',
  },
  {
    key: 27,
    value: 'Gestão de Riscos',
    title: 'Gestão de Riscos',
  },
  {
    key: 28,
    value: 'Projetos',
    title: 'Projetos',
  },
  {
    key: 29,
    value: 'Expansão e Novos Negócios',
    title: 'Expansão e Novos Negócios',
  },
  {
    key: 30,
    value: 'Suporte Técnico',
    title: 'Suporte Técnico',
  },
  {
    key: 31,
    value: 'Facilities (Gestão de Instalações)',
    title: 'Facilities (Gestão de Instalações)',
  },
  {
    key: 32,
    value: 'Eventos e Promoções',
    title: 'Eventos e Promoções',
  },
  {
    key: 33,
    value: 'Desenvolvimento Organizacional',
    title: 'Desenvolvimento Organizacional',
  },
  {
    key: 34,
    value: 'Inteligência de Mercado',
    title: 'Inteligência de Mercado',
  },
  {
    key: 35,
    value: 'Experiência do Cliente (CX)',
    title: 'Experiência do Cliente (CX)',
  },
  {
    key: 36,
    value: 'Relacionamento com Investidores',
    title: 'Relacionamento com Investidores',
  },
  {
    key: 37,
    value: 'Gestão de Ativos',
    title: 'Gestão de Ativos',
  },
  {
    key: 38,
    value: 'Planejamento Financeiro',
    title: 'Planejamento Financeiro',
  },
  {
    key: 39,
    value: 'Tesouraria',
    title: 'Tesouraria',
  },
  {
    key: 40,
    value: 'Seguros e Benefícios',
    title: 'Seguros e Benefícios',
  },
  {
    key: 41,
    value: 'Gestão de Contratos',
    title: 'Gestão de Contratos',
  },
  {
    key: 42,
    value: 'Outro',
    title: 'Outro',
  },
]
